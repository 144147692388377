/**
 * @memberOf momentshare.models.event
 * @constructor
 */
export class EventDownload {
  constructor({ id, path, createdAt, expiresAt, status, totalSizeInBytes, totalFiles }) {
    this.id = id
    this.path = path
    this.createdAt = createdAt
    this.expiresAt = expiresAt
    this.status = status
    this.totalSizeInBytes = totalSizeInBytes
    this.totalFiles = totalFiles
  }

  get sizeInMb() {
    return this.getConvertedSize(1000000)
  }

  get sizeInGb() {
    return this.getConvertedSize(1000000000)
  }

  get formattedSize() {
    if (!this.sizeInMb) {
      return ''
    }

    if (this.sizeInMb < 1000) {
      return `${this.sizeInMb} MB`
    }

    return `${this.sizeInGb} GB`
  }

  /**
   * @param {number} number
   * @return {string|null}
   */
  getConvertedSize(number) {
    if (typeof this.totalSizeInBytes !== 'number') {
      return null
    }

    return (this.totalSizeInBytes / number).toFixed(2)
  }
}
