import { EVENT_PROPERTY_KEY } from '~/lib/constants/event'

/**
 * @typedef {ThemeService}
 * @alias this.$themeService
 */
export class ThemeService {
  constructor(nuxtApp) {
    this.nuxtApp = nuxtApp
  }

  init() {
    this.$t = this.nuxtApp.$i18n.t
    this.$eventService = this.nuxtApp.$eventService
  }

  /**
   * @return {Array}
   */
  getThemes() {
    return [
      {
        name: this.$t('theme_translations.wedding.theme_name'),
        image: '/themes/wedding/card-image.jpg',
        properties: {
          [EVENT_PROPERTY_KEY.FONT]: 'Dancing Script',
          [EVENT_PROPERTY_KEY.UPLOAD_TEXT]: this.$t('theme_translations.wedding.image_upload_text'),
          [EVENT_PROPERTY_KEY.UPLOAD_TEXT_ALIGNMENT]: 'center',
          [EVENT_PROPERTY_KEY.BUTTON_BACKGROUND_COLOR]: '#4D1C00B2',
          [EVENT_PROPERTY_KEY.BACKGROUND_COLOR]: '#4d1c00',
          [EVENT_PROPERTY_KEY.BACKGROUND_IMAGE]: '/themes/wedding/background.jpg',
          [EVENT_PROPERTY_KEY.TEXT_COLOR]: '#ffffff',
          [EVENT_PROPERTY_KEY.BUTTON_BORDER_COLOR]: '#4d1c00',
          [EVENT_PROPERTY_KEY.BUTTON_BORDER_ENABLED]: true,
          [EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION]: '26.5%',
          [EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION]: '50%',
          [EVENT_PROPERTY_KEY.BUTTON_BORDER_RADIUS]: '3',
          [EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE]: '3',
          [EVENT_PROPERTY_KEY.BUTTON_BORDER_WIDTH]: '1',

          // Mobile
          ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE]: '4',
          ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION]: '20%',
          ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION]: '50%',
        },
      },
      {
        name: this.$t('theme_translations.party.theme_name'),
        image: '/themes/party/card-image.jpg',
        properties: {
          [EVENT_PROPERTY_KEY.FONT]: 'Merriweather Sans',
          [EVENT_PROPERTY_KEY.UPLOAD_TEXT]: this.$t('theme_translations.party.image_upload_text'),
          [EVENT_PROPERTY_KEY.UPLOAD_TEXT_ALIGNMENT]: 'center',
          [EVENT_PROPERTY_KEY.BUTTON_BACKGROUND_COLOR]: '#1511CFa1',
          [EVENT_PROPERTY_KEY.BACKGROUND_COLOR]: '#000000',
          [EVENT_PROPERTY_KEY.BACKGROUND_IMAGE]: '/themes/party/background.jpg',
          [EVENT_PROPERTY_KEY.TEXT_COLOR]: '#ffffff',
          [EVENT_PROPERTY_KEY.BUTTON_BORDER_COLOR]: '#1511CF',
          [EVENT_PROPERTY_KEY.BUTTON_BORDER_ENABLED]: true,
          [EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION]: '20%',
          [EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION]: '50%',
          [EVENT_PROPERTY_KEY.BUTTON_BORDER_RADIUS]: '3',
          [EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE]: '3',
          [EVENT_PROPERTY_KEY.BUTTON_BORDER_WIDTH]: '2',

          // Mobile
          ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE]: '4',
          ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION]: '10%',
          ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION]: '50%',
        },
      },
      {
        name: this.$t('theme_translations.sport.theme_name'),
        image: '/themes/sport/card-image.jpg',
        properties: {
          [EVENT_PROPERTY_KEY.FONT]: 'Bebas Neue',
          [EVENT_PROPERTY_KEY.UPLOAD_TEXT]: this.$t('theme_translations.sport.image_upload_text'),
          [EVENT_PROPERTY_KEY.UPLOAD_TEXT_ALIGNMENT]: 'center',
          [EVENT_PROPERTY_KEY.BUTTON_BACKGROUND_COLOR]: '#000000B2',
          [EVENT_PROPERTY_KEY.BACKGROUND_COLOR]: '#000000',
          [EVENT_PROPERTY_KEY.BACKGROUND_IMAGE]: '/themes/sport/background.jpg',
          [EVENT_PROPERTY_KEY.TEXT_COLOR]: '#ffffff',
          [EVENT_PROPERTY_KEY.BUTTON_BORDER_COLOR]: '#000000',
          [EVENT_PROPERTY_KEY.BUTTON_BORDER_ENABLED]: true,
          [EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION]: '26.5%',
          [EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION]: '50%',
          [EVENT_PROPERTY_KEY.BUTTON_BORDER_RADIUS]: '3',
          [EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE]: '3',
          [EVENT_PROPERTY_KEY.BUTTON_BORDER_WIDTH]: '1',

          // Mobile
          ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE]: '4',
          ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION]: '20%',
          ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION]: '50%',
        },
      },
      {
        name: this.$t('theme_translations.company.theme_name'),
        image: '/themes/company/card-image.jpg',
        properties: {
          [EVENT_PROPERTY_KEY.FONT]: 'Bree Serif',
          [EVENT_PROPERTY_KEY.UPLOAD_TEXT]: this.$t('theme_translations.company.image_upload_text'),
          [EVENT_PROPERTY_KEY.UPLOAD_TEXT_ALIGNMENT]: 'center',
          [EVENT_PROPERTY_KEY.BUTTON_BACKGROUND_COLOR]: '#783200B2',
          [EVENT_PROPERTY_KEY.BACKGROUND_COLOR]: '#783200',
          [EVENT_PROPERTY_KEY.BACKGROUND_IMAGE]: '/themes/company/background.jpg',
          [EVENT_PROPERTY_KEY.TEXT_COLOR]: '#ffffff',
          [EVENT_PROPERTY_KEY.BUTTON_BORDER_COLOR]: '#783200',
          [EVENT_PROPERTY_KEY.BUTTON_BORDER_ENABLED]: true,
          [EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION]: '23.5%',
          [EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION]: '50%',
          [EVENT_PROPERTY_KEY.BUTTON_BORDER_RADIUS]: '3',
          [EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE]: '3',
          [EVENT_PROPERTY_KEY.BUTTON_BORDER_WIDTH]: '1',

          // Mobile
          ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE]: '4',
          ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION]: '13%',
          ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION]: '50%',
        },
      },
      {
        name: this.$t('theme_translations.festival.theme_name'),
        image: '/themes/festival/card-image.jpg',
        properties: {
          [EVENT_PROPERTY_KEY.FONT]: 'Open Sans',
          [EVENT_PROPERTY_KEY.UPLOAD_TEXT]: this.$t('theme_translations.festival.image_upload_text'),
          [EVENT_PROPERTY_KEY.UPLOAD_TEXT_ALIGNMENT]: 'center',
          [EVENT_PROPERTY_KEY.BUTTON_BACKGROUND_COLOR]: '#ffffff',
          [EVENT_PROPERTY_KEY.BACKGROUND_COLOR]: '#ffffff',
          [EVENT_PROPERTY_KEY.BACKGROUND_IMAGE]: '/themes/festival/background.jpg',
          [EVENT_PROPERTY_KEY.TEXT_COLOR]: '#101010',
          [EVENT_PROPERTY_KEY.BUTTON_BORDER_COLOR]: '#101010',
          [EVENT_PROPERTY_KEY.BUTTON_BORDER_ENABLED]: true,
          [EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION]: '20.5%',
          [EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION]: '50%',
          [EVENT_PROPERTY_KEY.BUTTON_BORDER_RADIUS]: '3',
          [EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE]: '3',
          [EVENT_PROPERTY_KEY.BUTTON_BORDER_WIDTH]: '1',

          // Mobile
          ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE]: '4',
          ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION]: '10%',
          ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION]: '50%',
        },
      },
      {
        name: this.$t('theme_translations.birthday.theme_name'),
        image: '/themes/birthday/card-image.jpg',
        properties: {
          [EVENT_PROPERTY_KEY.FONT]: 'Roboto',
          [EVENT_PROPERTY_KEY.UPLOAD_TEXT]: this.$t('theme_translations.birthday.image_upload_text'),
          [EVENT_PROPERTY_KEY.UPLOAD_TEXT_ALIGNMENT]: 'center',
          [EVENT_PROPERTY_KEY.BUTTON_BACKGROUND_COLOR]: '#BD00FFB2',
          [EVENT_PROPERTY_KEY.BACKGROUND_COLOR]: '#BD00FF',
          [EVENT_PROPERTY_KEY.BACKGROUND_IMAGE]: '/themes/birthday/background.jpg',
          [EVENT_PROPERTY_KEY.TEXT_COLOR]: '#ffffff',
          [EVENT_PROPERTY_KEY.BUTTON_BORDER_COLOR]: '#BD00FF',
          [EVENT_PROPERTY_KEY.BUTTON_BORDER_ENABLED]: true,
          [EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION]: '22.5%',
          [EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION]: '50%',
          [EVENT_PROPERTY_KEY.BUTTON_BORDER_RADIUS]: '3',
          [EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE]: '3',
          [EVENT_PROPERTY_KEY.BUTTON_BORDER_WIDTH]: '1',

          // Mobile
          ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE]: '4',
          ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION]: '12%',
          ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION]: '50%',
        },
      },
      {
        name: this.$t('theme_translations.trip.theme_name'),
        image: '/themes/trip/card-image.jpg',
        properties: {
          [EVENT_PROPERTY_KEY.FONT]: 'Prompt',
          [EVENT_PROPERTY_KEY.UPLOAD_TEXT]: this.$t('theme_translations.trip.image_upload_text'),
          [EVENT_PROPERTY_KEY.UPLOAD_TEXT_ALIGNMENT]: 'center',
          [EVENT_PROPERTY_KEY.BUTTON_BACKGROUND_COLOR]: '#FFFFFFB2',
          [EVENT_PROPERTY_KEY.BACKGROUND_COLOR]: '#ffffff',
          [EVENT_PROPERTY_KEY.BACKGROUND_IMAGE]: '/themes/trip/background.jpg',
          [EVENT_PROPERTY_KEY.TEXT_COLOR]: '#000000',
          [EVENT_PROPERTY_KEY.BUTTON_BORDER_COLOR]: '#424242',
          [EVENT_PROPERTY_KEY.BUTTON_BORDER_ENABLED]: true,
          [EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION]: '17.5%',
          [EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION]: '50%',
          [EVENT_PROPERTY_KEY.BUTTON_BORDER_RADIUS]: '3',
          [EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE]: '3',
          [EVENT_PROPERTY_KEY.BUTTON_BORDER_WIDTH]: '1',

          // Mobile
          ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE]: '4',
          ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION]: '8%',
          ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION]: '50%',
        },
      },
    ]
  }

  /**
   * @param {momentshare.models.event.Event} event
   * @param {Object} theme
   */
  getThemeIsActive(theme, event) {
    return event[EVENT_PROPERTY_KEY.BACKGROUND_IMAGE] === theme.properties[EVENT_PROPERTY_KEY.BACKGROUND_IMAGE]
  }

  /**
   * @param {momentshare.models.event.Event} event
   * @return Object|null
   */
  getCurrentThemeByEvent(event) {
    return this.getThemes().find((theme) => this.getThemeIsActive(theme, event)) ?? null
  }

  resetEventToTheme(eventId, theme) {
    const formData = new FormData()

    // Reset original image paths
    formData.set(this.getMappedEventPropertyKey(EVENT_PROPERTY_KEY.ORIGINAL_BACKGROUND_IMAGE), null)
    formData.set(this.getMappedEventPropertyKey(EVENT_PROPERTY_KEY.ORIGINAL_BACKGROUND_IMAGE, true), null)

    // Reset image states
    formData.set(this.getMappedEventPropertyKey('backgroundImageState'), null)
    formData.set(this.getMappedEventPropertyKey('backgroundImageState', true), null)

    // Remove deprecated properties
    formData.set(this.getMappedEventPropertyKey(EVENT_PROPERTY_KEY.BACKGROUND_IMAGE_UPLOAD), null)
    formData.set(this.getMappedEventPropertyKey(EVENT_PROPERTY_KEY.BACKGROUND_IMAGE_UPLOAD, true), null)

    // Set all theme properties
    Object.keys(theme.properties).forEach((key) => {
      // Set base property
      const value = theme.properties[key] ?? null

      formData.set(key, value)

      if (key.startsWith('mobile_') || Object.keys(theme.properties).includes('mobile_' + key)) {
        return
      }

      formData.set('mobile_' + key, null)
    })

    return this.$eventService.updateEvent(eventId, formData)
  }

  getMappedEventPropertyKey(eventPropertyKey, mobile = false) {
    return mobile ? `mobile_${eventPropertyKey}` : eventPropertyKey
  }
}
