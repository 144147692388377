/**
 * @memberOf momentshare.models.user
 * @constructor
 */
export class ConnectedDriveData {
  constructor({ userEmail, totalSpace, usedSpace }) {
    this.userEmail = userEmail
    this.totalSpace = totalSpace
    this.usedSpace = usedSpace
  }
}
