import { defineStore } from 'pinia'

const TOAST_TIMEOUT_DURATION = 2500
let toastMessageTimeout = null

export const useToastStore = defineStore('toast', {
  state: () => ({
    isOpen: false,
    text: null,
    icon: null,
    secondary: false,
  }),
  actions: {
    /**
     * @param {string} text
     * @param {string} [icon]
     * @param {number} [duration] in ms
     * @param {boolean} [secondary]
     */
    showToastMessage({ text, icon, duration = TOAST_TIMEOUT_DURATION, secondary = false }) {
      if (!text) {
        return
      }

      clearTimeout(toastMessageTimeout)

      if (icon) {
        this.icon = icon
      }

      this.text = text
      this.secondary = secondary
      this.isOpen = true

      toastMessageTimeout = setTimeout(() => {
        this.isOpen = false

        // The animation needs to finish before removing the text/icon
        setTimeout(() => {
          this.text = null
          this.icon = null
          this.secondary = false
        }, 250)
      }, duration)
    },
  },
})
