/**
 * @typedef {CurrencyService}
 * @alias this.$currencyService
 */
export class CurrencyService {
  #locale
  #defaultCurrencyDisplay = 'code'

  constructor(locale) {
    this.#locale = locale
  }

  /**
   * @param {number|string} price
   * @param {string} [currency]
   * @param {string} [locale]
   * @return {string} price in locale string
   */
  formatToLocalePrice(price, currency = 'EUR', locale = this.#locale) {
    let localePriceFormat
    const currencyDisplay = this.#getCurrencyDisplay(locale)

    try {
      localePriceFormat = new Intl.NumberFormat(locale, { style: 'currency', currency, currencyDisplay }).format(price)
    } catch (error) {
      console.warn(error)
    }

    return localePriceFormat
  }

  /**
   * @param {number|string} priceInCents
   * @param {string} [currency]
   * @param {string} [locale]
   * @return {string} price in locale string
   */
  formatCentsToLocalePrice(priceInCents, currency = 'EUR', locale = this.#locale) {
    return this.formatToLocalePrice(Number(priceInCents) / 100, currency, locale)
  }

  /**
   * @param {string} [locale]
   * @return {string} currency display strings: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#parameters
   */
  #getCurrencyDisplay(locale = this.#locale) {
    const localesWithSymbolDisplay = ['fr', 'es']
    return localesWithSymbolDisplay.includes(locale) ? 'symbol' : this.#defaultCurrencyDisplay
  }

  /**
   * @param number
   * @returns {number}
   */
  roundToTwoDecimals(number) {
    return +(Math.round(number + 'e+2') + 'e-2')
  }

  /**
   * @example '12.50' -> 1250
   * @param {string|number} amount
   * @returns {number}
   */
  parseAmountToCents(amount) {
    const parsedAmount = Number(amount)
    const str = parsedAmount.toString()
    const [int] = str.split('.')

    return Number(
      parsedAmount
        .toFixed(2)
        .replace('.', '')
        .padEnd(int.length === 1 ? 3 : 4, '0'),
    )
  }

  /**
   * @example 1250 -> 12.50
   * @param {number} cents
   * @returns {number}
   */
  parseCentsToAmount(cents) {
    return Number(Number(Math.abs(cents) / 100).toFixed(2))
  }
}
