import nlMessages from '../assets/messages/nl.json'
import enMessages from '../assets/messages/en.json'
import frMessages from '../assets/messages/fr.json'
import esMessages from '../assets/messages/es.json'
import deMessages from '../assets/messages/de.json'

export default defineI18nConfig(() => {
  return {
    fallbackLocale: 'en',
    messages: { ...nlMessages, ...enMessages, ...frMessages, ...esMessages, ...deMessages },
    missingWarn: false,
    fallbackWarn: false,
    warnHtmlMessage: false,
  }
})
