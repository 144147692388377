/**
 * @memberOf momentshare.models.event
 * @constructor
 */
export class EventProperty {
  constructor({ id, key, value, mobileValue, validUntil }) {
    this.id = id
    this.key = key
    this.value = value
    this.mobileValue = mobileValue
    this.validUntil = validUntil
  }
}
