import { ROUTES } from '~/lib/constants/routes'
import { useUserStore } from '~/stores/user'
import { storeToRefs } from 'pinia'

export function useLinks() {
  const router = useRouter()
  const localePath = useLocalePath()
  const userStore = useUserStore()
  const { isAuthenticated } = storeToRefs(userStore)

  const goToHomepage = function () {
    return router.push(localePath('/'))
  }

  const helpLink = computed(() => localePath(ROUTES.HELP_FAQ))
  const aboutLink = computed(() => localePath(ROUTES.ABOUT))
  const pricesLink = computed(() => localePath(ROUTES.PRICES))
  const howItWorksLink = computed(() => localePath(ROUTES.HOW_IT_WORKS))

  const startOrder = function (queryParam = '') {
    if (isAuthenticated.value) {
      return router.push(localePath(`${ROUTES.EVENT_ORDER}${queryParam}`))
    }

    return router.push(localePath(`${ROUTES.REGISTER}${queryParam}`))
  }

  return { goToHomepage, startOrder, helpLink, aboutLink, pricesLink, howItWorksLink }
}
