/*
  Original idea is from a PR of xanido:  https://github.com/willmcpo/body-scroll-lock/pull/133/files
  His fix customizes the original package https://github.com/willmcpo/body-scroll-lock/ so it
  allows horizontal scrolling within the target element.
  I again altered his PR by adding a check for if the y or x axis is being scrolled so the scroll
  doesn't lock when you're at the horizontal scroll end (see comment from line 141 to 143 and 150).
  **NOTE**
  The PR fix uses v3.0.3 of the body-scroll-lock package. Should body-scroll-lock ever update and fix
  this issue we should revert to using the original body-scroll-lock package.
*/

// ===================

// Older browsers don't support event options, feature detect it.

// Adopted and modified solution from Bohdan Didukh (2017)
// https://stackoverflow.com/questions/41594997/ios-10-safari-prevent-scrolling-behind-a-fixed-overlay-and-maintain-scroll-posi

let hasPassiveEvents = false
if (typeof window !== 'undefined') {
  const passiveTestOptions = {
    get passive() {
      hasPassiveEvents = true
      return undefined
    },
  }
  window.addEventListener('testPassive', null, passiveTestOptions)
  window.removeEventListener('testPassive', null, passiveTestOptions)
}

const isIosDevice =
  typeof window !== 'undefined' &&
  window.navigator &&
  window.navigator.platform &&
  /iP(ad|hone|od)/.test(window.navigator.platform)

let locks = []
let documentListenerAdded = false
let initialClient = { x: -1, y: -1 }
let previousBodyOverflowSetting
let previousBodyPaddingRight

let axis = null

// returns true if `el` should be allowed to receive touchmove events.
const allowTouchMove = (el) =>
  locks.some((lock) => {
    if (lock.options.allowTouchMove && lock.options.allowTouchMove(el)) {
      return true
    }

    return false
  })

const preventDefault = (rawEvent) => {
  const e = rawEvent || window.event

  // For the case whereby consumers adds a touchmove event listener to document.
  // Recall that we do document.addEventListener('touchmove', preventDefault, { passive: false })
  // in disableBodyScroll - so if we provide this opportunity to allowTouchMove, then
  // the touchmove event on document will break.
  if (allowTouchMove(e.target)) {
    return true
  }

  // Do not prevent if the event has more than one touch (usually meaning this is a multi touch gesture like pinch to zoom).
  if (e.touches.length > 1) return true

  if (e.preventDefault) e.preventDefault()

  return false
}

const setOverflowHidden = (options) => {
  // Setting overflow on body/documentElement synchronously in Desktop Safari slows down
  // the responsiveness for some reason. Setting within a setTimeout fixes this.
  setTimeout(() => {
    // If previousBodyPaddingRight is already set, don't set it again.
    if (previousBodyPaddingRight === undefined) {
      const reserveScrollBarGap = !!options && options.reserveScrollBarGap === true
      const scrollBarGap = window.innerWidth - document.documentElement.clientWidth

      if (reserveScrollBarGap && scrollBarGap > 0) {
        previousBodyPaddingRight = document.body.style.paddingRight
        document.body.style.paddingRight = `${scrollBarGap}px`
      }
    }

    // If previousBodyOverflowSetting is already set, don't set it again.
    if (previousBodyOverflowSetting === undefined) {
      previousBodyOverflowSetting = document.body.style.overflow
      document.body.style.overflow = 'hidden'
    }
  })
}

const restoreOverflowSetting = () => {
  // Setting overflow on body/documentElement synchronously in Desktop Safari slows down
  // the responsiveness for some reason. Setting within a setTimeout fixes this.
  setTimeout(() => {
    if (previousBodyPaddingRight !== undefined) {
      document.body.style.paddingRight = previousBodyPaddingRight

      // Restore previousBodyPaddingRight to undefined so setOverflowHidden knows it
      // can be set again.
      previousBodyPaddingRight = undefined
    }

    if (previousBodyOverflowSetting !== undefined) {
      document.body.style.overflow = previousBodyOverflowSetting

      // Restore previousBodyOverflowSetting to undefined
      // so setOverflowHidden knows it can be set again.
      previousBodyOverflowSetting = undefined
    }
  })
}

// https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
const isTargetElementTotallyScrolled = (targetElement, axis) => {
  if (targetElement) {
    const totalScroll = targetElement[`scroll${axis === 'y' ? 'Height' : 'Width'}`]
    const scrolled = targetElement[`scroll${axis === 'y' ? 'Top' : 'Left'}`]
    const clientSize = targetElement[`client${axis === 'y' ? 'Height' : 'Width'}`]
    return totalScroll - scrolled <= clientSize
  }
  return false
}

const handleScroll = (event, targetElement, axis) => {
  const touch = event.targetTouches[0]
  const initialPos = initialClient[axis]
  const scrollPos = targetElement && targetElement[`scroll${axis === 'y' ? 'Top' : 'Left'}`]
  const clientPos = (axis === 'y' ? touch.clientY : touch.clientX) - initialPos

  if (allowTouchMove(event.target)) {
    return false
  }

  // Somehow if we let this in and we scroll the x-axis untill the end we occasionally can't
  // scroll back. For now we only do this on the y-axis but we'll have to figure out a more
  // solid solution in the future.
  if (targetElement && axis === 'y' && scrollPos === 0 && clientPos > 0) {
    // element is at the top of its scroll.
    return preventDefault(event)
  }

  // Allow scrolling on x axis
  if (axis === 'y' && isTargetElementTotallyScrolled(targetElement, axis) && clientPos < 0) {
    // element is at the top of its scroll.
    return preventDefault(event)
  }

  event.stopPropagation()
  return true
}

export const disableBodyScroll = (targetElement, options) => {
  if (isIosDevice) {
    // targetElement must be provided, and disableBodyScroll must not have been
    // called on this targetElement before.
    if (!targetElement) {
      console.error(
        'disableBodyScroll unsuccessful - targetElement must be provided when calling disableBodyScroll on IOS devices.',
      )
      return
    }

    if (targetElement && !locks.some((lock) => lock.targetElement === targetElement)) {
      const lock = {
        targetElement,
        options: options || {},
      }

      locks = [...locks, lock]

      targetElement.ontouchstart = (event) => {
        if (event.targetTouches.length === 1) {
          // detect single touch.
          initialClient = {
            x: event.targetTouches[0].clientX,
            y: event.targetTouches[0].clientY,
          }
          axis = null
        }
      }
      targetElement.ontouchmove = (event) => {
        if (event.targetTouches.length === 1) {
          // detect single touch.
          if (!axis) {
            const distX = Math.abs(initialClient.x - event.targetTouches[0].clientX)
            const distY = Math.abs(initialClient.y - event.targetTouches[0].clientY)
            axis = distX > distY ? 'x' : 'y'
          }
          handleScroll(event, targetElement, axis)
        }
      }

      if (!documentListenerAdded) {
        document.addEventListener('touchmove', preventDefault, hasPassiveEvents ? { passive: false } : undefined)
        documentListenerAdded = true
      }
    }
  } else {
    setOverflowHidden(options)
    const lock = {
      targetElement,
      options: options || {},
    }

    locks = [...locks, lock]
  }
}

export const clearAllBodyScrollLocks = () => {
  if (isIosDevice) {
    // Clear all locks ontouchstart/ontouchmove handlers, and the references.
    locks.forEach((lock) => {
      lock.targetElement.ontouchstart = null
      lock.targetElement.ontouchmove = null
    })

    if (documentListenerAdded) {
      document.removeEventListener('touchmove', preventDefault, hasPassiveEvents ? { passive: false } : undefined)
      documentListenerAdded = false
    }

    locks = []

    // Reset initial clientY.
    initialClient = { x: -1, y: -1 }
    axis = null
  } else {
    restoreOverflowSetting()
    locks = []
  }
}

export const enableBodyScroll = (targetElement) => {
  if (isIosDevice) {
    if (!targetElement) {
      console.error(
        'enableBodyScroll unsuccessful - targetElement must be provided when calling enableBodyScroll on IOS devices.',
      )
      return
    }

    targetElement.ontouchstart = null
    targetElement.ontouchmove = null

    locks = locks.filter((lock) => lock.targetElement !== targetElement)

    if (documentListenerAdded && locks.length === 0) {
      document.removeEventListener('touchmove', preventDefault, hasPassiveEvents ? { passive: false } : undefined)

      documentListenerAdded = false
    }
  } else {
    locks = locks.filter((lock) => lock.targetElement !== targetElement)
    if (!locks.length) {
      restoreOverflowSetting()
    }
  }
}
