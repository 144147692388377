/**
 * @typedef {DateTimeService}
 * @alias this.$dateTimeService
 */
export class DateTimeService {
  #locale
  #invalidError = 'Invalid params given'
  #defaultInlOptions = { year: 'numeric', month: 'long', day: 'numeric' }
  #settings = {
    amDesignator: '',
    dateSeparator: '-',
    fullDateTimePattern: 'dddd d MMMM yyyy HH:mm:ss',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    monthDayPattern: 'd MMMM',
    pmDesignator: '',
    shortDatePattern: 'd-M-yyyy',
    shortTimePattern: 'HH:mm',
    timeSeparator: ':',
  }

  constructor(locale) {
    this.#locale = locale
  }

  //PUBLIC METHODS

  /**
   * @param {string} locale
   * If you want to overrule the setted locale
   */
  setLocale(locale) {
    this.#locale = locale
  }

  /**
   * @param {string} dateTime
   * @return {object} Date object
   */
  convertToDateTime(dateTime) {
    let dateTimeObject = new Date()

    if (dateTime) {
      try {
        //CONVERT - by / or else safari won't accept it as date
        dateTimeObject = new Date(dateTime.replace(/-/g, '/'))
      } catch (error) {
        console.warn(error)
      }
    }

    return dateTimeObject
  }

  /**
   * @param {Date|string} dateTime
   * @return {string} full day name like Monday, Tuesday etc.
   */
  getDayName(dateTime) {
    return this.intlDateTimeFormat(dateTime, { weekday: 'long' })
  }

  /**
   * @param {Date|string} dateTime
   * @return {string} full month name like December, January etc.
   */
  getMonthName(dateTime) {
    return this.intlDateTimeFormat(dateTime, { month: 'long' })
  }

  /**
   * @param {Date|string} dateTime
   * @return {string} short day name like Sun, Mon etc.
   */
  getShortDayName(dateTime) {
    return this.intlDateTimeFormat(dateTime, { weekday: 'short' })
  }

  /**
   * @param {Date|string} dateTime
   * @return {string} short month name like Dec, Jan etc.
   */
  getShortMonthName(dateTime) {
    return this.intlDateTimeFormat(dateTime, { month: 'short' })
  }

  /**
   * @param {Date|string} dateTime
   * @return {string} 30 aug 2024, 11:48
   */
  getDateTimeWithShortMonth(dateTime) {
    return this.intlDateTimeFormat(dateTime, {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })
  }

  /**
   * @param {Date|string} dateTime
   * @return {string} 30 aug 2024, 11:48
   */
  getDateWithLongMonth(dateTime) {
    return this.intlDateTimeFormat(dateTime, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })
  }

  /**
   * @param {Date|string} dateTime
   * @param {object} options: https://devhints.io/wip/intl-datetime
   * @return {string} international formatted date string based on options
   */
  intlDateTimeFormat(dateTime, options = this.#defaultInlOptions) {
    if (!dateTime || !options) return this.#invalidError

    if (typeof dateTime === 'string') dateTime = this.convertToDateTime(dateTime)

    return new Intl.DateTimeFormat(this.#locale, options).format(dateTime)
  }

  /**
   * @param {Date|string} dateTime
   * @param {string} format
   * @return {string} formatted date string based on format
   */
  format(dateTime, format) {
    if (!dateTime || !format) return this.#invalidError

    if (typeof dateTime === 'string') dateTime = this.convertToDateTime(dateTime)

    const day = dateTime.getDate()
    const month = dateTime.getMonth() + 1
    const year = dateTime.getFullYear()
    const fullHours = dateTime.getHours()
    const hours = fullHours > 12 ? fullHours - 12 : fullHours
    const minutes = dateTime.getMinutes()
    const seconds = dateTime.getSeconds()
    const milliSeconds = dateTime.getMilliseconds()

    //PREDEFINED PATTERNS
    if (!format) format = `${this.#settings.shortDatePattern} ${this.#settings.shortTimePattern}`
    else if (format === 'd') format = this.#settings.shortDatePattern
    else if (format === 'D') format = this.#settings.longDatePattern
    else if (format === 'D0') format = this.#removeDay(this.#settings.longDatePattern)
    else if (format === 't') format = this.#settings.shortTimePattern
    else if (format === 'T') format = this.#settings.longTimePattern
    else if (format === 'f') format = `${this.#settings.longDatePattern} ${this.#settings.shortTimePattern}`
    else if (format === 'f0') {
      format = this.#removeDay(`${this.#settings.longDatePattern} ${this.#settings.shortTimePattern}`)
    } else if (format === 'F') format = this.#settings.fullDateTimePattern
    else if (format === 'F0') format = this.#removeDay(this.#settings.fullDateTimePattern)
    else if (format === 'g') format = `${this.#settings.shortDatePattern} ${this.#settings.shortTimePattern}`
    else if (format === 'G') format = `${this.#settings.shortDatePattern} ${this.#settings.longTimePattern}`
    else if (format === 'M' || format === 'm') format = this.#settings.monthDayPattern

    let result = format.replace('%', '')

    result = result.replace(/yyyy/g, year.toString())
    result = result.replace(/MMMM/g, '!@#$#@!')
    result = result.replace(/MMM/g, '@#$%$#@')
    result = result.replace(/MM/g, this.#fillOut(month))
    result = result.replace(/M/g, month.toString())
    result = result.replace(/dddd/g, '#$%^%$#')
    result = result.replace(/ddd/g, '$%^&^%$')
    result = result.replace(/dd/g, this.#fillOut(day))
    result = result.replace(/d/g, day.toString())
    result = result.replace(/HH/g, this.#fillOut(fullHours))
    result = result.replace(/H/g, fullHours.toString())
    result = result.replace(/hh/g, this.#fillOut(hours))
    result = result.replace(/h/g, hours.toString())
    result = result.replace(/mm/g, this.#fillOut(minutes))
    result = result.replace(/m/g, minutes.toString())
    result = result.replace(/ss/g, this.#fillOut(seconds))
    result = result.replace(/s/g, seconds.toString())
    result = result.replace(/fff/g, this.#extend(milliSeconds, 3))
    result = result.replace(/ff/g, this.#extend(milliSeconds, 2))
    result = result.replace(/f/g, this.#extend(milliSeconds, 1))
    result = result.replace(/tt/g, fullHours > 12 ? this.#settings.pmDesignator : this.#settings.amDesignator)
    result = result.replace(
      /t/g,
      fullHours > 12 ? this.#settings.pmDesignator.substr(0, 1) : this.#settings.amDesignator.substr(0, 1),
    )

    result = result.replace(/!@#\$#@!/g, this.getMonthName(dateTime))
    result = result.replace(/@#\$%\$#@/g, this.getShortMonthName(dateTime))
    result = result.replace(/#\$%\^%\$#/g, this.getDayName(dateTime))
    result = result.replace(/\$%\^&\^%\$/g, this.getShortDayName(dateTime))

    result = result.replace(/\//g, this.#settings.dateSeparator)
    result = result.replace(/-/g, this.#settings.dateSeparator)
    result = result.replace(/\./g, this.#settings.dateSeparator)
    result = result.replace(/:/g, this.#settings.timeSeparator)

    return result
  }

  //PRIVATE METHODS
  /**
   * @param {number} number
   * @param {number} [digits]
   * @return {string} number string with added 0 if missing
   */
  #fillOut(number, digits = 2) {
    let result = number.toString()

    while (result.length < digits) result = '0' + result

    return result
  }

  /**
   * @param {string} formatString
   * @return {string} trimmed formatString without dddd or dd;
   */
  #removeDay(formatString) {
    return formatString.replace('dddd', '').replace('ddd', '').trim()
  }

  /**
   * @param {number} number
   * @param {number} digits
   * @return {string} extend string with added milliseconds
   */
  #extend(number, digits) {
    let result = this.#fillOut(number, digits)

    if (result.length > digits) {
      const diff = result.length - digits
      result = Math.round(number / Math.pow(10, diff))
    }

    return result
  }
}
