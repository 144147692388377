/**
 * @typedef {BrowserHelperService}
 * @alias this.$browserHelperService
 */
export class BrowserHelperService {
  get isSafari() {
    if (import.meta.server) {
      return false
    }

    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  }
}
