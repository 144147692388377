/**
 * @class momentshare.models.base
 * @memberOf momentshare.models
 */
export class Base {
  isUndefined(value) {
    return value === undefined
  }

  /**
   * Create a deep copy of the (extended) model
   * @returns {this}
   */
  deepCopy() {
    const deep = {}

    Object.keys(this).forEach((key) => {
      deep[key] = this.cloneValue(this[key])
    })

    return Object.assign(new this.constructor(deep))
  }

  cloneValue(value) {
    if (value instanceof Base) {
      return value.deepCopy()
    }

    if (Array.isArray(value)) {
      return value.map((v) => this.cloneValue(v))
    }

    if (value instanceof Date) {
      return new Date(value)
    }

    if (typeof value === 'object' && value !== null) {
      const newValue = {}

      Object.keys(value).forEach((key) => {
        newValue[key] = this.cloneValue(value[key])
      })

      return newValue
    }

    return value
  }

  /**
   * Assign value(s) to deep copy of object
   *
   * Can be called with:
   * - 2 arguments, key/value
   * - 1 argument, object with key/value pairs
   *
   * @param {string|Object} key
   * @param {*} [value]
   * @returns {this}
   */
  // eslint-disable-next-line
  assign(key, value) {
    if (typeof arguments[0] === 'object') {
      return this.assignMultiple(arguments[0])
    }

    return this.assignValue(arguments[0], arguments[1])
  }

  /**
   * Assign multiple key/value pairs to a copy of the object.
   * Value may be anything (even methods)
   * @param {Object} values
   * @returns {this}
   */
  assignMultiple(values) {
    const copy = this.deepCopy()

    for (const key in values) {
      if (!Object.hasOwn(values, key) || !this.isAssignable(key)) {
        continue
      }

      copy[key] = values[key]
    }

    return copy
  }

  /**
   * Assign a single value to deep copy of object
   * @param {string|number} key
   * @param {*} value
   * @returns {this}
   */
  assignValue(key, value) {
    const copy = this.deepCopy()

    copy[key] = value

    return copy
  }

  /**
   * Returns if key is a property or a function in the model
   * @param {string} key
   * @returns {boolean}
   */
  isAssignable(key) {
    return Object.hasOwn(this, key) || typeof this.constructor.prototype[key] === 'function'
  }
}
