import { Base } from '~/lib/models/base/base'

export class EventUploadButtonState extends Base {
  constructor({
    text,
    font,
    backgroundColor,
    color,
    top,
    left,
    fontSize,
    borderRadius,
    border,
    borderWidth,
    borderColor,
    borderStyle,
    textAlignment,
  } = {}) {
    super()

    this.text = text
    this.font = font ?? 'Open Sans'
    this.backgroundColor = backgroundColor ?? '#000000'
    this.color = color ?? '#ffffff'
    this.top = top ?? '50%'
    this.left = left ?? '25%'
    this.fontSize = fontSize ?? 3
    this.borderRadius = borderRadius ?? 1
    this.border = border ?? true
    this.borderWidth = borderWidth ?? 1
    this.borderColor = borderColor ?? '#ffffff'
    this.textAlignment = textAlignment ?? 'center'

    // Not available for customization yet
    this.borderStyle = borderStyle ?? 'solid'
  }

  get formattedFontSize() {
    return this.fontSize + 'em'
  }

  get formattedBorderRadius() {
    return this.borderRadius / 10 + 'em'
  }

  get formattedBorderWidth() {
    return this.borderWidth / 10 + 'em'
  }
}
