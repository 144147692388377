export default {
  nl: [
    {
      name: 'Klant',
      text: 'Het is makkelijk om de foto’s te uploaden en daarna ook weer te downloaden zodat ze bewaard kunnen blijven als het event klaar is.',
      type: 'wedding',
    },
    {
      name: 'Anette',
      text: 'Hele goede service vooraf. Top systeem dat voor een grote groep goed werkt (650 personen) én simpel is in gebruik.',
      type: 'wedding',
    },
    {
      name: 'Karina',
      text: "Het is een heel mooi concept, niet moeilijk. Heb je vragen krijg je meteen antwoord hierop via app in mijn geval; prima! En je hebt foto's en filmpje vanuit elke hoek vd zaal. Iedereen was enthousiast, echt super!",
      type: 'wedding',
    },
    {
      name: 'Sjoerd',
      text: 'Heel stabiel platform, beeldmateriaal is makkelijk te uploaden zonder app. Je kunt leuke dingen doen met vormgeving zodat je het echt passend kunt maken voor je evenement. Enige nadeel is dat je de beelden niet makkelijk chronologisch kan sorteren. Dat zou een mooie toevoeging zijn.',
      type: 'wedding',
    },
    {
      name: 'Sepp',
      text: 'Echt fantastisch gewoon heel erg handig en makkelijk te creëren en echt een meerwaarde voor ons huwelijks feest. We hebben zulke leuke beelden gezien vanuit onze gasten die echt fantastisch waren en zo krijg je echt een all-round beeld van onze bruiloft. In een woord top.',
      type: 'wedding',
    },
    {
      name: 'Duimenrace.nl',
      text: 'Tijdens onze Duimenrace (liften rond het IJsselmeer) wilden wij het publiek in de binnenstad van Hoorn laten zien wat onze deelnemers meemaakten. Momentshare heeft hier in kunnen faciliteren door zowel foto als video eenvoudig door deelnemers te kunnen laten delen.',
      type: 'party',
    },
    {
      name: 'van Beckhoven',
      text: 'Zeker meerwaarde voor ons huwelijk! Super gemakkelijke manier om de gasten van onze bruiloft hun foto’s met ons te laten delen. Is veel gebruik van gemaakt. Event is makkelijk aan te maken en met de QR makkelijk te benaderen door de gasten. Aanrader!',
      type: 'wedding',
    },
    {
      name: 'Femke & Gertjan',
      text: 'Super handig! Wij hebben hele leuke foto’s van onze dag ontvangen. De mails zijn duidelijk en als je een vraag hebt is er via whatsapp echt direct contact! Super! Aanrader dus!',
      type: 'wedding',
    },
    {
      name: 'Vanessa',
      text: 'Heel duidelijk, reargeren snel op mail. Werkt perfect!!!',
      type: 'wedding',
    },
    {
      name: 'Bianca',
      text: 'Super leuk om tijdens ons meerdaagse Ferrari Club evenement foto’s te delen met de overige deelnemers !',
      type: 'party',
    },
    {
      name: 'Gino',
      text: 'Gebruiksvriendelijke website en alles heel logisch. je kan meteen aan de slag en alles werkt goed. had een paar vragen en die werden binnen een uur via WhatsApp beantwoord. Zeker een aanrader voor een feestje of evenement!',
      type: 'party',
    },
    {
      name: 'Jan',
      text: 'Fijn contact met de helpdesk ivm een foutmelding in Safari. Snel en correct geholpen.',
      type: 'wedding',
    },
    {
      name: 'Susan',
      text: 'Na oriëntatie wat de mogelijkheden zijn een mail gestuurd met vragen. Hier duidelijke antwoorden op gekregen. Het fijne is dat je met je camera alle mogelijkheden kan gebruiken. Het feest in de avond was in een donkere ruimte dus gebruik van flits heel fijn.',
      type: 'wedding',
    },
    {
      name: 'Stephanie',
      text: 'Het was erg leuk om onze bruiloft te beleven door de lens van onze gasten. De vormgeving kan mooi worden aangepast naar jouw stijlthema. Op een smartphone is het niet altijd helder dat je alle foto’s kunt zien door naar beneden te scrollen.',
      type: 'wedding',
    },
    {
      name: 'Marye',
      text: 'Snel en makkelijk geregeld en écht een toevoeging aan het feest!',
      type: 'party',
    },
    {
      name: 'Annelies',
      text: "Super! foto's oploaden zonder het installeren van een app! Zeer tevreden!",
      type: 'party',
    },
    {
      name: 'Eugenio',
      text: 'Alles werkte perfect en al onze herinneringen van de mooiste dag van ons leven staat vast en we konden alles zonder problemen downloaden. Top service en top systeem!',
      type: 'wedding',
    },
  ],
  en: [
    {
      name: 'Customer',
      text: "It's easy to upload photos and then download them again so they can be preserved after the event is over.",
      type: 'wedding',
    },
    {
      name: 'Anette',
      text: 'Very good service beforehand. Great system that works well for a large group (650 people) and is easy to use.',
      type: 'wedding',
    },
    {
      name: 'Karina',
      text: "It's a very nice concept, not difficult. If you have questions, you get an immediate response via the app in my case; great! And you have photos and videos from every corner of the hall. Everyone was enthusiastic, really fantastic!",
      type: 'wedding',
    },
    {
      name: 'Sjoerd',
      text: "Very stable platform, media is easy to upload without an app. You can do fun things with design to make it really fit your event. The only downside is that you can't easily sort the images chronologically. That would be a great addition.",
      type: 'wedding',
    },
    {
      name: 'Sepp',
      text: 'Absolutely fantastic, very handy and easy to create, and really added value to our wedding party. We saw such great footage from our guests that were truly amazing, giving us an all-around view of our wedding. One word: awesome.',
      type: 'wedding',
    },
    {
      name: 'Duimenrace.nl',
      text: 'During our Duimenrace (hitchhiking around the IJsselmeer), we wanted to show the public in the city center of Hoorn what our participants were experiencing. Momentshare facilitated this by allowing both photos and videos to be easily shared by participants.',
      type: 'party',
    },
    {
      name: 'van Beckhoven',
      text: "Definitely added value to our wedding! A super easy way for our wedding guests to share their photos with us. It was widely used. The event is easy to create, and with the QR code, it's easy for guests to access. Highly recommended!",
      type: 'wedding',
    },
    {
      name: 'Femke & Gertjan',
      text: 'Super handy! We received some really fun photos from our day. The emails are clear, and if you have a question, there is really instant contact via WhatsApp! Amazing! Highly recommended!',
      type: 'wedding',
    },
    {
      name: 'Vanessa',
      text: 'Very clear, quick response to emails. Works perfectly!',
      type: 'wedding',
    },
    {
      name: 'Bianca',
      text: 'Super fun to share photos with other participants during our multi-day Ferrari Club event!',
      type: 'party',
    },
    {
      name: 'Gino',
      text: 'User-friendly website and everything is very logical. You can get started right away, and everything works well. I had a few questions, and they were answered within an hour via WhatsApp. Definitely recommended for a party or event!',
      type: 'party',
    },
    {
      name: 'Jan',
      text: 'Good contact with the helpdesk regarding an error in Safari. Quickly and correctly assisted.',
      type: 'wedding',
    },
    {
      name: 'Susan',
      text: 'After researching the possibilities, I sent an email with questions. I received clear answers. The nice thing is that you can use all options with your camera. The evening party was in a dark space, so using the flash was very helpful.',
      type: 'wedding',
    },
    {
      name: 'Stephanie',
      text: "It was so much fun to experience our wedding through the lens of our guests. The design can be nicely adjusted to your style theme. On a smartphone, it's not always clear that you can see all the photos by scrolling down.",
      type: 'wedding',
    },
    {
      name: 'Marye',
      text: 'Quick and easy to set up and truly an addition to the party!',
      type: 'party',
    },
    {
      name: 'Annelies',
      text: 'Super! Upload photos without installing an app! Very satisfied!',
      type: 'party',
    },
    {
      name: 'Eugenio',
      text: 'Everything worked perfectly, and all our memories of the most beautiful day of our lives are preserved, and we could download everything without any problems. Excellent service and excellent system!',
      type: 'wedding',
    },
  ],
  es: [
    {
      name: 'Cliente',
      text: 'Es fácil subir fotos y luego descargarlas para conservarlas después del evento.',
      type: 'wedding',
    },
    {
      name: 'Anette',
      text: 'Muy buen servicio previo. Gran sistema que funciona bien para un grupo grande (650 personas) y es fácil de usar.',
      type: 'wedding',
    },
    {
      name: 'Karina',
      text: 'Es un concepto muy bonito, no complicado. Si tienes preguntas, recibes una respuesta inmediata a través de la aplicación, en mi caso; ¡excelente! Y tienes fotos y videos desde cada rincón del salón. Todos estaban entusiasmados, ¡realmente fantástico!',
      type: 'wedding',
    },
    {
      name: 'Sjoerd',
      text: 'Plataforma muy estable, es fácil subir el material sin una aplicación. Puedes hacer cosas divertidas con el diseño para que se adapte realmente a tu evento. El único inconveniente es que no puedes ordenar las imágenes cronológicamente fácilmente. Sería una gran adición.',
      type: 'wedding',
    },
    {
      name: 'Sepp',
      text: 'Absolutamente fantástico, muy práctico y fácil de crear, y realmente fue un valor añadido para nuestra fiesta de bodas. Vimos imágenes increíbles de nuestros invitados, que eran realmente impresionantes, dando una visión completa de nuestra boda. En una palabra: increíble.',
      type: 'wedding',
    },
    {
      name: 'Duimenrace.nl',
      text: 'Durante nuestra Duimenrace (haciendo autostop alrededor del IJsselmeer), queríamos mostrar al público en el centro de Hoorn lo que nuestros participantes estaban experimentando. Momentshare facilitó esto al permitir que los participantes compartieran fotos y videos fácilmente.',
      type: 'party',
    },
    {
      name: 'van Beckhoven',
      text: 'Definitivamente un valor añadido para nuestra boda. ¡Una manera súper fácil para que nuestros invitados compartan sus fotos con nosotros! Fue ampliamente utilizado. El evento es fácil de crear, y con el código QR, es fácil de acceder para los invitados. ¡Altamente recomendado!',
      type: 'wedding',
    },
    {
      name: 'Femke & Gertjan',
      text: '¡Súper práctico! Recibimos fotos muy divertidas de nuestro día. Los correos son claros, y si tienes una pregunta, hay contacto instantáneo por WhatsApp. ¡Increíble! ¡Muy recomendado!',
      type: 'wedding',
    },
    {
      name: 'Vanessa',
      text: 'Muy claro, responden rápidamente a los correos. ¡Funciona perfectamente!',
      type: 'wedding',
    },
    {
      name: 'Bianca',
      text: '¡Súper divertido compartir fotos con otros participantes durante nuestro evento de varios días del Ferrari Club!',
      type: 'party',
    },
    {
      name: 'Gino',
      text: 'Página web fácil de usar y todo muy lógico. Puedes empezar de inmediato, y todo funciona bien. Tuve algunas preguntas, y las respondieron en una hora a través de WhatsApp. ¡Definitivamente recomendado para una fiesta o evento!',
      type: 'party',
    },
    {
      name: 'Jan',
      text: 'Buen contacto con el servicio técnico debido a un error en Safari. Rápido y correctamente asistido.',
      type: 'wedding',
    },
    {
      name: 'Susan',
      text: 'Después de investigar las posibilidades, envié un correo con preguntas. Recibí respuestas claras. Lo bueno es que puedes usar todas las opciones con tu cámara. La fiesta nocturna fue en un espacio oscuro, por lo que el uso del flash fue muy útil.',
      type: 'wedding',
    },
    {
      name: 'Stephanie',
      text: 'Fue muy divertido experimentar nuestra boda a través de los ojos de nuestros invitados. El diseño se puede ajustar a tu tema de estilo. En un smartphone, no siempre está claro que puedes ver todas las fotos desplazándote hacia abajo.',
      type: 'wedding',
    },
    {
      name: 'Marye',
      text: '¡Rápido y fácil de configurar y realmente una adición a la fiesta!',
      type: 'party',
    },
    {
      name: 'Annelies',
      text: '¡Súper! ¡Sube fotos sin instalar una aplicación! ¡Muy satisfecha!',
      type: 'party',
    },
    {
      name: 'Eugenio',
      text: 'Todo funcionó perfectamente, y todos nuestros recuerdos del día más hermoso de nuestras vidas están guardados, y pudimos descargar todo sin problemas. ¡Excelente servicio y excelente sistema!',
      type: 'wedding',
    },
  ],
  de: [
    {
      name: 'Kunde',
      text: 'Es ist einfach, Fotos hochzuladen und sie später wieder herunterzuladen, damit sie nach dem Event erhalten bleiben.',
      type: 'wedding',
    },
    {
      name: 'Anette',
      text: 'Sehr guter Service im Vorfeld. Ein tolles System, das für eine große Gruppe (650 Personen) gut funktioniert und einfach zu bedienen ist.',
      type: 'wedding',
    },
    {
      name: 'Karina',
      text: 'Es ist ein sehr schönes Konzept, nicht kompliziert. Wenn man Fragen hat, bekommt man sofort eine Antwort, in meinem Fall über die App; prima! Und man hat Fotos und Videos aus jeder Ecke des Saals. Alle waren begeistert, wirklich fantastisch!',
      type: 'wedding',
    },
    {
      name: 'Sjoerd',
      text: 'Sehr stabile Plattform, Medien lassen sich leicht ohne App hochladen. Man kann mit dem Design tolle Dinge machen, damit es wirklich zu deinem Event passt. Einziger Nachteil ist, dass man die Bilder nicht einfach chronologisch sortieren kann. Das wäre eine tolle Ergänzung.',
      type: 'wedding',
    },
    {
      name: 'Sepp',
      text: 'Absolut fantastisch, sehr praktisch und leicht zu erstellen, und wirklich ein Mehrwert für unsere Hochzeitsfeier. Wir haben so großartige Bilder von unseren Gästen gesehen, die wirklich beeindruckend waren, und so erhält man einen umfassenden Eindruck unserer Hochzeit. Mit einem Wort: top.',
      type: 'wedding',
    },
    {
      name: 'Duimenrace.nl',
      text: 'Während unserer Duimenrace (Trampen rund um das IJsselmeer) wollten wir dem Publikum in der Innenstadt von Hoorn zeigen, was unsere Teilnehmer erlebten. Momentshare hat dies ermöglicht, indem Teilnehmer Fotos und Videos einfach teilen konnten.',
      type: 'party',
    },
    {
      name: 'van Beckhoven',
      text: 'Definitiv ein Mehrwert für unsere Hochzeit! Eine super einfache Möglichkeit, unseren Hochzeitsgästen ihre Fotos mit uns teilen zu lassen. Es wurde viel genutzt. Das Event ist leicht zu erstellen, und mit dem QR-Code ist es für die Gäste leicht zugänglich. Sehr empfehlenswert!',
      type: 'wedding',
    },
    {
      name: 'Femke & Gertjan',
      text: 'Super praktisch! Wir haben wirklich schöne Fotos von unserem Tag erhalten. Die E-Mails sind klar, und wenn man eine Frage hat, gibt es sofort Kontakt über WhatsApp! Super! Sehr zu empfehlen!',
      type: 'wedding',
    },
    {
      name: 'Vanessa',
      text: 'Sehr klar, schnelle Antwort auf E-Mails. Funktioniert perfekt!',
      type: 'wedding',
    },
    {
      name: 'Bianca',
      text: 'Super, während unseres mehrtägigen Ferrari-Club-Events Fotos mit den anderen Teilnehmern zu teilen!',
      type: 'party',
    },
    {
      name: 'Gino',
      text: 'Benutzerfreundliche Webseite, und alles ist sehr logisch. Man kann sofort loslegen, und alles funktioniert gut. Ich hatte ein paar Fragen, die innerhalb einer Stunde über WhatsApp beantwortet wurden. Definitiv zu empfehlen für eine Party oder ein Event!',
      type: 'party',
    },
    {
      name: 'Jan',
      text: 'Guter Kontakt mit dem Helpdesk wegen einer Fehlermeldung in Safari. Schnell und korrekt geholfen.',
      type: 'wedding',
    },
    {
      name: 'Susan',
      text: 'Nach einer Orientierung über die Möglichkeiten habe ich eine E-Mail mit Fragen geschickt. Ich habe klare Antworten darauf erhalten. Das Schöne ist, dass man mit der Kamera alle Möglichkeiten nutzen kann. Die Abendveranstaltung fand in einem dunklen Raum statt, daher war die Nutzung des Blitzes sehr hilfreich.',
      type: 'wedding',
    },
    {
      name: 'Stephanie',
      text: 'Es war so schön, unsere Hochzeit durch die Linse unserer Gäste zu erleben. Das Design kann schön an dein Stilthema angepasst werden. Auf einem Smartphone ist es nicht immer klar, dass man alle Fotos sehen kann, indem man nach unten scrollt.',
      type: 'wedding',
    },
    {
      name: 'Marye',
      text: 'Schnell und einfach eingerichtet und wirklich eine Bereicherung für die Party!',
      type: 'party',
    },
    {
      name: 'Annelies',
      text: 'Super! Fotos hochladen ohne Installation einer App! Sehr zufrieden!',
      type: 'party',
    },
    {
      name: 'Eugenio',
      text: 'Alles hat perfekt funktioniert, und all unsere Erinnerungen an den schönsten Tag unseres Lebens sind festgehalten, und wir konnten alles problemlos herunterladen. Top-Service und Top-System!',
      type: 'wedding',
    },
  ],
  fr: [
    {
      name: 'Client',
      text: "Il est facile de télécharger des photos, puis de les télécharger à nouveau pour les conserver après l'événement.",
      type: 'wedding',
    },
    {
      name: 'Anette',
      text: 'Très bon service en amont. Système génial qui fonctionne bien pour un grand groupe (650 personnes) et facile à utiliser.',
      type: 'wedding',
    },
    {
      name: 'Karina',
      text: "C'est un concept très sympa, pas compliqué. Si vous avez des questions, vous obtenez une réponse immédiate via l'application dans mon cas; super! Et vous avez des photos et des vidéos de tous les coins de la salle. Tout le monde était enthousiaste, vraiment fantastique!",
      type: 'wedding',
    },
    {
      name: 'Sjoerd',
      text: "Plateforme très stable, les médias sont faciles à télécharger sans application. Vous pouvez faire des choses amusantes avec le design pour l'adapter vraiment à votre événement. Le seul inconvénient est que vous ne pouvez pas facilement trier les images par ordre chronologique. Ce serait un excellent ajout.",
      type: 'wedding',
    },
    {
      name: 'Sepp',
      text: "Absolument fantastique, très pratique et facile à créer, et vraiment une valeur ajoutée pour notre fête de mariage. Nous avons vu des images incroyables de nos invités qui étaient vraiment fantastiques, ce qui nous a donné une vue d'ensemble de notre mariage. En un mot : super.",
      type: 'wedding',
    },
    {
      name: 'Duimenrace.nl',
      text: "Pendant notre Duimenrace (auto-stop autour de l'IJsselmeer), nous voulions montrer au public du centre-ville de Hoorn ce que nos participants vivaient. Momentshare a permis cela en facilitant le partage de photos et de vidéos par les participants.",
      type: 'party',
    },
    {
      name: 'van Beckhoven',
      text: "Certainement une valeur ajoutée pour notre mariage ! Une manière super simple pour nos invités de partager leurs photos avec nous. Cela a été largement utilisé. L'événement est facile à créer, et avec le QR code, il est facile d'accès pour les invités. Hautement recommandé !",
      type: 'wedding',
    },
    {
      name: 'Femke & Gertjan',
      text: 'Super pratique ! Nous avons reçu de très belles photos de notre journée. Les emails sont clairs, et si vous avez une question, il y a un contact instantané via WhatsApp ! Super ! Hautement recommandé !',
      type: 'wedding',
    },
    {
      name: 'Vanessa',
      text: 'Très clair, réponse rapide aux emails. Fonctionne parfaitement !',
      type: 'wedding',
    },
    {
      name: 'Bianca',
      text: "Super amusant de partager des photos avec d'autres participants pendant notre événement Ferrari Club de plusieurs jours !",
      type: 'party',
    },
    {
      name: 'Gino',
      text: "Site web convivial et tout est très logique. Vous pouvez commencer immédiatement, et tout fonctionne bien. J'avais quelques questions, et elles ont été répondues dans l'heure via WhatsApp. Certainement recommandé pour une fête ou un événement !",
      type: 'party',
    },
    {
      name: 'Jan',
      text: 'Bon contact avec le support technique concernant une erreur dans Safari. Rapide et efficace.',
      type: 'wedding',
    },
    {
      name: 'Susan',
      text: "Après avoir étudié les possibilités, j'ai envoyé un email avec des questions. J'ai reçu des réponses claires. Ce qui est bien, c'est que vous pouvez utiliser toutes les options avec votre appareil photo. La soirée était dans un endroit sombre, donc l'utilisation du flash était très utile.",
      type: 'wedding',
    },
    {
      name: 'Stephanie',
      text: "C'était tellement amusant de vivre notre mariage à travers les yeux de nos invités. Le design peut être ajusté à votre thème de style. Sur un smartphone, il n'est pas toujours clair que vous pouvez voir toutes les photos en faisant défiler vers le bas.",
      type: 'wedding',
    },
    {
      name: 'Marye',
      text: 'Rapide et facile à configurer et vraiment un ajout à la fête !',
      type: 'party',
    },
    {
      name: 'Annelies',
      text: "Super ! Téléchargez des photos sans installer d'application ! Très satisfait !",
      type: 'party',
    },
    {
      name: 'Eugenio',
      text: 'Tout a parfaitement fonctionné, et tous nos souvenirs du plus beau jour de notre vie sont préservés, et nous avons pu tout télécharger sans problème. Service au top et système au top !',
      type: 'wedding',
    },
  ],
}
