export const EVENT_PROPERTY_KEY = {
  FONT: 'font',
  UPLOAD_TEXT: 'uploadText',
  BACKGROUND_COLOR: 'backgroundColor',
  ENABLE_GALLERY: 'enableGallery',
  BACKGROUND_IMAGE_UPLOAD: 'backgroundImageUpload',
  BACKGROUND_IMAGE: 'backgroundImage',
  TEXT_COLOR: 'textColor',
  USE_GOOGLE_DRIVE: 'useGoogleDrive',
  HOSTING_ENABLED: 'hostingEnabled',
  USE_DROPBOX: 'useDropbox',
  LARGE_VIDEOS_ALLOWED: 'largeVideosAllowed',
  DEFAULT_GALLERY_SORTING: 'defaultGallerySorting',

  // V2
  BUTTON_BACKGROUND_COLOR: 'buttonBackgroundColor',
  BUTTON_TOP_POSITION: 'buttonTopPosition',
  BUTTON_LEFT_POSITION: 'buttonLeftPosition',
  BUTTON_FONT_SIZE: 'buttonFontSize',
  BUTTON_BORDER_RADIUS: 'buttonBorderRadius',
  BUTTON_BORDER_ENABLED: 'buttonBorderEnabled',
  BUTTON_BORDER_WIDTH: 'buttonBorderWidth',
  BUTTON_BORDER_COLOR: 'buttonBorderColor',
  ORIGINAL_BACKGROUND_IMAGE: 'originalBackgroundImage',
  UPLOAD_TEXT_ALIGNMENT: 'uploadTextAlignment',
  UPLOADING_FOR_GUESTS_ENABLED: 'uploadingForGuestsEnabled',
}
