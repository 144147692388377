export class BasicEventInformation {
  /**
   * @param {string} id
   * @param {string} name
   * @param {string} status
   * @param {Date} date
   */
  constructor({ id, name, status, date }) {
    this.id = id
    this.name = name
    this.status = status
    this.date = date
  }
}
