export class ColorService {
  /**
   * Example: #000000FF
   * Returns value between 0 and 1, for example 0.5
   * @param {string} hexColor
   * @returns {number}
   */
  getAlphaFromHex(hexColor) {
    // Remove # if present
    const formattedHexColor = hexColor.replace('#', '')

    // Check if the hex color has an alpha channel (8 characters)
    if (formattedHexColor.length !== 8) {
      return 1
    }

    const alpha = formattedHexColor.slice(6, 8)
    const alphaDecimal = parseInt(alpha, 16)

    return Math.round((alphaDecimal / 255) * 100) / 100
  }

  /**
   * Between 0 and 1, for example 0.5
   * @param {number} transparency 0 - 1
   * @returns {string}
   */
  convertTransparencyToAlphaHex(transparency) {
    return Math.round(transparency * 255)
      .toString(16)
      .padStart(2, '0')
  }
}
