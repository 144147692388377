import { defineStore } from 'pinia'

import actions from './actions'

export const useImagesStore = defineStore('imagesStore', {
  state: () => ({
    images: [],
    hasMorePages: false,
  }),
  actions,
})
