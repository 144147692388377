/**
 * @memberOf momentshare.models.vat
 * @constructor
 */
export class VatNumberResult {
  constructor({ valid, countryCode, vatNumber, name, address, strAddress }) {
    this.valid = valid
    this.countryCode = countryCode
    this.vatNumber = vatNumber
    this.name = name
    this.address = address
    this.strAddress = strAddress
  }
}
