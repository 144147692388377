import { mapOrders, mapOrder } from '~/lib/mappers/order-mapper'

/**
 * @typedef {OrderService}
 * @alias this.$orderService
 */
export class OrderService {
  constructor(nuxtApp) {
    this.nuxtApp = nuxtApp
  }

  init() {
    this.$apiService = this.nuxtApp.$apiService
  }

  /**
   * @param {Array} subscriptionOptionIds
   * @param {momentshare.models.event.CreateEvent} event
   * @param {String} discountCode
   * @returns {Promise<String>} payment redirect link
   */
  createOrder(subscriptionOptionIds, event, discountCode) {
    return this.$apiService.instance
      .post('/order/create', {
        subscriptionOptionIds,
        eventName: event.name,
        eventDate: event.epochDateTime,
        eventProperties: event.properties,
        discountCode,
      })
      .then((response) => response.data)
  }

  /**
   * @param {Array} subscriptionOptionIds
   * @param {string} eventId
   * @param {string} eventId
   * @param {momentshare.models.payment.DiscountCode} discountCodeDetails
   * @returns {Promise<String>} payment redirect link
   */
  upgrade(subscriptionOptionIds, eventId, discountCodeDetails) {
    return this.$apiService.instance
      .post(`/order/upgrade/${eventId}`, { subscriptionOptionIds, discountCode: discountCodeDetails?.discountCode })
      .then((response) => response.data)
  }

  /**
   * @param {string} eventId
   * @param {Object} discountCodeDetails
   * @return {Promise<String>} payment redirect link
   */
  extend({ eventId, discountCodeDetails }) {
    return this.$apiService.instance
      .post(`/order/extend/${eventId}`, { discountCode: discountCodeDetails?.discountCode })
      .then((response) => response.data)
  }

  /**
   * @returns {Promise<momentshare.models.order.Order[]>}
   */
  getOrders() {
    return this.$apiService.instance
      .get('/order')
      .then((response) => mapOrders(response.data))
      .then((orders) => orders.filter((order) => order.paymentStatus === 'paid'))
  }

  /**
   * @param {string} eventId
   * @param {string} [gclid]
   * @param {string} [fbclid]
   * @returns {Promise<momentshare.models.order.Order>}
   */
  getOrder(eventId, gclid, fbclid) {
    const params = new URLSearchParams({
      ...(gclid && { gclid }),
      ...(fbclid && { fbclid }),
    })

    const formattedSearchParams = params.toString()
    const formattedQueryParams = formattedSearchParams.length ? `?${formattedSearchParams}` : ''

    return this.$apiService.instance
      .get(`/order/from-event/${eventId}${formattedQueryParams}`)
      .then((response) => mapOrder(response.data))
  }

  /**
   * @param {string} eventId
   * @param {string} discountCode
   */
  getExtendOption({ eventId, discountCode }) {
    return this.$apiService.instance
      .get(`/order/extend-price/${eventId}${discountCode ? `?discountCode=${discountCode}` : ''}`)
      .then((response) => response.data)
  }
}
