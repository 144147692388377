import { Event } from '../models/event'
import { EventProperty } from '../models/event-property'
import { EVENT_PROPERTY_KEY } from '~/lib/constants/event'
import { BasicEventInformation } from '~/lib/models/basic-event-information'

export function mapEvents(events, filesPath) {
  return events.map((event) => mapEvent(event, filesPath))
}

export function mapEvent(event, filesPath) {
  return new Event({
    id: event.id,
    name: event.name,
    status: event.status,
    safeName: event.safeName,
    date: new Date(event.epochDateTime),
    publicationDate: event.publicationEpochDateTime ? new Date(event.publicationEpochDateTime) : null,
    publicationExpiresAt: event.publicationExpiresAtEpochDateTime
      ? new Date(event.publicationExpiresAtEpochDateTime)
      : null,
    shortLink: event.shortLink,
    properties: event.properties ? mapEventProperties(event.properties, filesPath) : null,
  })
}

export function mapEventProperties(eventProperties, filesPath) {
  return eventProperties.map((property) => mapEventProperty(property, filesPath))
}

export function mapEventProperty(property, filesPath) {
  return new EventProperty({
    id: property.id,
    key: property.key,
    value: mapValue(property.key, property.value, filesPath),
    mobileValue: mapValue(property.key, property.mobileValue, filesPath),
    validUntil: new Date(property.validUntil),
  })
}

function mapValue(key, value, filesPath) {
  const fileKeys = [
    EVENT_PROPERTY_KEY.BACKGROUND_IMAGE_UPLOAD,
    EVENT_PROPERTY_KEY.BACKGROUND_IMAGE,
    EVENT_PROPERTY_KEY.ORIGINAL_BACKGROUND_IMAGE,
  ]

  if (!value) return value

  if (!fileKeys.includes(key)) {
    return value
  }

  if (value?.includes('/themes')) {
    return value
  }

  return `${filesPath}/${value}`
}

export function mapBasicEventInformation(basicEventInformation) {
  return new BasicEventInformation({
    id: basicEventInformation.id,
    name: basicEventInformation.name,
    status: basicEventInformation.status,
    date: new Date(basicEventInformation.epochDateTime),
  })
}
