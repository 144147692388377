import { VatNumberResult } from '~/lib/models/vat-number-result'

export function mapVatNumberResult(vatNumberResult) {
  return new VatNumberResult({
    valid: vatNumberResult.valid,
    countryCode: vatNumberResult.countryCode,
    vatNumber: vatNumberResult.vatNumber,
    name: vatNumberResult.name,
    address: vatNumberResult.address,
    strAddress: vatNumberResult.strAddress,
  })
}
