import { useToastStore } from '~/stores/toast'

export class NetworkConnectionService {
  constructor(nuxtApp) {
    this.nuxtApp = nuxtApp
    this.toastStore = useToastStore()
    this.warningShown = false
    this.connection = null
    this.isOnline = true
  }

  init() {
    this.$t = this.nuxtApp.$i18n.t
  }

  startConnectionMonitor() {
    this.connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection
    if (!this.connection) {
      return
    }

    this.checkConnectionSpeed()
    this.checkHasInternet()

    this.connection.addEventListener('change', () => this.checkConnectionSpeed())
  }

  checkConnectionSpeed() {
    if (this.warningShown || !this.connection) return

    if (this.connection.downlink < 7 || this.connection.effectiveType === '3g') {
      this.warningShown = true
      this.toastStore.showToastMessage({
        text: this.$t('slow_internet_warning'),
        secondary: true,
        duration: 10000,
      })
    } else {
      this.warningShown = false
    }
  }

  checkHasInternet() {
    this.updateHasInternet()

    if (window) {
      window.addEventListener('online', () => this.updateHasInternet())
      window.addEventListener('offline', () => this.updateHasInternet())
    }
  }

  updateHasInternet() {
    this.isOnline = navigator.onLine
  }

  stopConnectionMonitor() {
    if (!this.connection) return

    this.connection.removeEventListener('change', () => this.checkConnectionSpeed())
    this.connection = null
    this.warningShown = false
  }
}
