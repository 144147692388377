import { EventDownload } from '~/lib/models/event-download'

export function mapEventDownloads(eventDownloads) {
  return eventDownloads.map((eventDownload) => mapEventDownload(eventDownload))
}

export function mapEventDownload(eventDownload) {
  return new EventDownload({
    id: eventDownload.id,
    path: eventDownload.path,
    createdAt: new Date(eventDownload.createdAt),
    expiresAt: new Date(eventDownload.expiresAt),
    status: eventDownload.status,
    totalSizeInBytes: eventDownload.totalSizeInBytes,
    totalFiles: eventDownload.totalFiles,
  })
}
