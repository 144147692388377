import { default as indexzs4BF2BLwQMeta } from "/usr/local/src/party-pictures-fe/pages/index.vue?macro=true";
import { default as indexCYlFPRQLQHMeta } from "/usr/local/src/party-pictures-fe/pages/about/index.vue?macro=true";
import { default as index3PpWHFMZhPMeta } from "/usr/local/src/party-pictures-fe/pages/account/connectors/index.vue?macro=true";
import { default as indexk268XUondVMeta } from "/usr/local/src/party-pictures-fe/pages/account/events/[event]/design/[type]/index.vue?macro=true";
import { default as indexcTWP0YSQTHMeta } from "/usr/local/src/party-pictures-fe/pages/account/events/[event]/design/index.vue?macro=true";
import { default as indexwR9qahYgI1Meta } from "/usr/local/src/party-pictures-fe/pages/account/events/[event]/index.vue?macro=true";
import { default as indexY1SmOE0YxrMeta } from "/usr/local/src/party-pictures-fe/pages/account/events/[event]/properties/index.vue?macro=true";
import { default as indexara1LA3gCeMeta } from "/usr/local/src/party-pictures-fe/pages/account/events/index.vue?macro=true";
import { default as orderJVuXo0azc4Meta } from "/usr/local/src/party-pictures-fe/pages/account/events/order.vue?macro=true";
import { default as eventsVdnzkdqxcPMeta } from "/usr/local/src/party-pictures-fe/pages/account/events.vue?macro=true";
import { default as indexGXPakvz61NMeta } from "/usr/local/src/party-pictures-fe/pages/account/help/index.vue?macro=true";
import { default as indexoZzpXD5vjsMeta } from "/usr/local/src/party-pictures-fe/pages/account/index.vue?macro=true";
import { default as indexvWV6QpqrgeMeta } from "/usr/local/src/party-pictures-fe/pages/account/orders/index.vue?macro=true";
import { default as indexlBtCkxsSoKMeta } from "/usr/local/src/party-pictures-fe/pages/account/user/index.vue?macro=true";
import { default as indexpxLgaj7EPxMeta } from "/usr/local/src/party-pictures-fe/pages/admin/index.vue?macro=true";
import { default as indextveohQJb0TMeta } from "/usr/local/src/party-pictures-fe/pages/birthday/index.vue?macro=true";
import { default as index5TgYPYbcD9Meta } from "/usr/local/src/party-pictures-fe/pages/christmas/index.vue?macro=true";
import { default as indexFVn2PZtGLhMeta } from "/usr/local/src/party-pictures-fe/pages/company-outing/index.vue?macro=true";
import { default as indexprdczH6kflMeta } from "/usr/local/src/party-pictures-fe/pages/end-of-year/index.vue?macro=true";
import { default as _91event_931ZY591QiaMMeta } from "/usr/local/src/party-pictures-fe/pages/event/[name]/[event].vue?macro=true";
import { default as indexCnih7ivg3KMeta } from "/usr/local/src/party-pictures-fe/pages/event/new/index.vue?macro=true";
import { default as indexEltUue70cRMeta } from "/usr/local/src/party-pictures-fe/pages/family-gathering/index.vue?macro=true";
import { default as indexBmCRcYT293Meta } from "/usr/local/src/party-pictures-fe/pages/help-faq/index.vue?macro=true";
import { default as indexqUdAqXXSgiMeta } from "/usr/local/src/party-pictures-fe/pages/how-it-works/index.vue?macro=true";
import { default as indexskK9UQZug8Meta } from "/usr/local/src/party-pictures-fe/pages/login/index.vue?macro=true";
import { default as indexQG4Orf9e0xMeta } from "/usr/local/src/party-pictures-fe/pages/prices/index.vue?macro=true";
import { default as index2xn16TASjZMeta } from "/usr/local/src/party-pictures-fe/pages/privacy-policy/index.vue?macro=true";
import { default as indexPBwaC8dJgTMeta } from "/usr/local/src/party-pictures-fe/pages/register/index.vue?macro=true";
import { default as indexiPX292p0ygMeta } from "/usr/local/src/party-pictures-fe/pages/reset-password/index.vue?macro=true";
import { default as indexxlbP6QkGgBMeta } from "/usr/local/src/party-pictures-fe/pages/sport-event/index.vue?macro=true";
import { default as indexxyGRys7uJPMeta } from "/usr/local/src/party-pictures-fe/pages/terms-and-conditions/index.vue?macro=true";
import { default as indexQaloAUePdNMeta } from "/usr/local/src/party-pictures-fe/pages/vacation/index.vue?macro=true";
import { default as indexiu2uzhV0yKMeta } from "/usr/local/src/party-pictures-fe/pages/wedding-anniversary/index.vue?macro=true";
import { default as index0iCLpeqJHGMeta } from "/usr/local/src/party-pictures-fe/pages/wedding/index.vue?macro=true";
import { default as component_45stubkgZDd53JZpMeta } from "/usr/local/src/party-pictures-fe/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubkgZDd53JZp } from "/usr/local/src/party-pictures-fe/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/usr/local/src/party-pictures-fe/pages/index.vue")
  },
  {
    name: "about___nl",
    path: "/nl/about",
    component: () => import("/usr/local/src/party-pictures-fe/pages/about/index.vue")
  },
  {
    name: "about___es",
    path: "/es/about",
    component: () => import("/usr/local/src/party-pictures-fe/pages/about/index.vue")
  },
  {
    name: "about___fr",
    path: "/fr/about",
    component: () => import("/usr/local/src/party-pictures-fe/pages/about/index.vue")
  },
  {
    name: "about___de",
    path: "/de/about",
    component: () => import("/usr/local/src/party-pictures-fe/pages/about/index.vue")
  },
  {
    name: "about___en",
    path: "/en/about",
    component: () => import("/usr/local/src/party-pictures-fe/pages/about/index.vue")
  },
  {
    name: "account-connectors___nl",
    path: "/nl/account/connectors",
    meta: index3PpWHFMZhPMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/connectors/index.vue")
  },
  {
    name: "account-connectors___es",
    path: "/es/account/connectors",
    meta: index3PpWHFMZhPMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/connectors/index.vue")
  },
  {
    name: "account-connectors___fr",
    path: "/fr/account/connectors",
    meta: index3PpWHFMZhPMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/connectors/index.vue")
  },
  {
    name: "account-connectors___de",
    path: "/de/account/connectors",
    meta: index3PpWHFMZhPMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/connectors/index.vue")
  },
  {
    name: "account-connectors___en",
    path: "/en/account/connectors",
    meta: index3PpWHFMZhPMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/connectors/index.vue")
  },
  {
    name: eventsVdnzkdqxcPMeta?.name,
    path: "/nl/account/events",
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events.vue"),
    children: [
  {
    name: "account-events-event-design-type___nl",
    path: ":event()/design/:type()",
    meta: indexk268XUondVMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/design/[type]/index.vue")
  },
  {
    name: "account-events-event-design___nl",
    path: ":event()/design",
    meta: indexcTWP0YSQTHMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/design/index.vue")
  },
  {
    name: "account-events-event___nl",
    path: ":event()",
    meta: indexwR9qahYgI1Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/index.vue")
  },
  {
    name: "account-events-event-properties___nl",
    path: ":event()/properties",
    meta: indexY1SmOE0YxrMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/properties/index.vue")
  },
  {
    name: "account-events___nl",
    path: "",
    meta: indexara1LA3gCeMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/index.vue")
  },
  {
    name: "account-events-order___nl",
    path: "order",
    meta: orderJVuXo0azc4Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/order.vue")
  }
]
  },
  {
    name: eventsVdnzkdqxcPMeta?.name,
    path: "/es/account/events",
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events.vue"),
    children: [
  {
    name: "account-events-event-design-type___es",
    path: ":event()/design/:type()",
    meta: indexk268XUondVMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/design/[type]/index.vue")
  },
  {
    name: "account-events-event-design___es",
    path: ":event()/design",
    meta: indexcTWP0YSQTHMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/design/index.vue")
  },
  {
    name: "account-events-event___es",
    path: ":event()",
    meta: indexwR9qahYgI1Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/index.vue")
  },
  {
    name: "account-events-event-properties___es",
    path: ":event()/properties",
    meta: indexY1SmOE0YxrMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/properties/index.vue")
  },
  {
    name: "account-events___es",
    path: "",
    meta: indexara1LA3gCeMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/index.vue")
  },
  {
    name: "account-events-order___es",
    path: "order",
    meta: orderJVuXo0azc4Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/order.vue")
  }
]
  },
  {
    name: eventsVdnzkdqxcPMeta?.name,
    path: "/fr/account/events",
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events.vue"),
    children: [
  {
    name: "account-events-event-design-type___fr",
    path: ":event()/design/:type()",
    meta: indexk268XUondVMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/design/[type]/index.vue")
  },
  {
    name: "account-events-event-design___fr",
    path: ":event()/design",
    meta: indexcTWP0YSQTHMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/design/index.vue")
  },
  {
    name: "account-events-event___fr",
    path: ":event()",
    meta: indexwR9qahYgI1Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/index.vue")
  },
  {
    name: "account-events-event-properties___fr",
    path: ":event()/properties",
    meta: indexY1SmOE0YxrMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/properties/index.vue")
  },
  {
    name: "account-events___fr",
    path: "",
    meta: indexara1LA3gCeMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/index.vue")
  },
  {
    name: "account-events-order___fr",
    path: "order",
    meta: orderJVuXo0azc4Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/order.vue")
  }
]
  },
  {
    name: eventsVdnzkdqxcPMeta?.name,
    path: "/de/account/events",
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events.vue"),
    children: [
  {
    name: "account-events-event-design-type___de",
    path: ":event()/design/:type()",
    meta: indexk268XUondVMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/design/[type]/index.vue")
  },
  {
    name: "account-events-event-design___de",
    path: ":event()/design",
    meta: indexcTWP0YSQTHMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/design/index.vue")
  },
  {
    name: "account-events-event___de",
    path: ":event()",
    meta: indexwR9qahYgI1Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/index.vue")
  },
  {
    name: "account-events-event-properties___de",
    path: ":event()/properties",
    meta: indexY1SmOE0YxrMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/properties/index.vue")
  },
  {
    name: "account-events___de",
    path: "",
    meta: indexara1LA3gCeMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/index.vue")
  },
  {
    name: "account-events-order___de",
    path: "order",
    meta: orderJVuXo0azc4Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/order.vue")
  }
]
  },
  {
    name: eventsVdnzkdqxcPMeta?.name,
    path: "/en/account/events",
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events.vue"),
    children: [
  {
    name: "account-events-event-design-type___en",
    path: ":event()/design/:type()",
    meta: indexk268XUondVMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/design/[type]/index.vue")
  },
  {
    name: "account-events-event-design___en",
    path: ":event()/design",
    meta: indexcTWP0YSQTHMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/design/index.vue")
  },
  {
    name: "account-events-event___en",
    path: ":event()",
    meta: indexwR9qahYgI1Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/index.vue")
  },
  {
    name: "account-events-event-properties___en",
    path: ":event()/properties",
    meta: indexY1SmOE0YxrMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/properties/index.vue")
  },
  {
    name: "account-events___en",
    path: "",
    meta: indexara1LA3gCeMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/index.vue")
  },
  {
    name: "account-events-order___en",
    path: "order",
    meta: orderJVuXo0azc4Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/order.vue")
  }
]
  },
  {
    name: "account-help___nl",
    path: "/nl/account/help",
    meta: indexGXPakvz61NMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/help/index.vue")
  },
  {
    name: "account-help___es",
    path: "/es/account/help",
    meta: indexGXPakvz61NMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/help/index.vue")
  },
  {
    name: "account-help___fr",
    path: "/fr/account/help",
    meta: indexGXPakvz61NMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/help/index.vue")
  },
  {
    name: "account-help___de",
    path: "/de/account/help",
    meta: indexGXPakvz61NMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/help/index.vue")
  },
  {
    name: "account-help___en",
    path: "/en/account/help",
    meta: indexGXPakvz61NMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/help/index.vue")
  },
  {
    name: "account___nl",
    path: "/nl/account",
    meta: indexoZzpXD5vjsMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/index.vue")
  },
  {
    name: "account___es",
    path: "/es/account",
    meta: indexoZzpXD5vjsMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/index.vue")
  },
  {
    name: "account___fr",
    path: "/fr/account",
    meta: indexoZzpXD5vjsMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/index.vue")
  },
  {
    name: "account___de",
    path: "/de/account",
    meta: indexoZzpXD5vjsMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/index.vue")
  },
  {
    name: "account___en",
    path: "/en/account",
    meta: indexoZzpXD5vjsMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/index.vue")
  },
  {
    name: "account-orders___nl",
    path: "/nl/account/orders",
    meta: indexvWV6QpqrgeMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/orders/index.vue")
  },
  {
    name: "account-orders___es",
    path: "/es/account/orders",
    meta: indexvWV6QpqrgeMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/orders/index.vue")
  },
  {
    name: "account-orders___fr",
    path: "/fr/account/orders",
    meta: indexvWV6QpqrgeMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/orders/index.vue")
  },
  {
    name: "account-orders___de",
    path: "/de/account/orders",
    meta: indexvWV6QpqrgeMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/orders/index.vue")
  },
  {
    name: "account-orders___en",
    path: "/en/account/orders",
    meta: indexvWV6QpqrgeMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/orders/index.vue")
  },
  {
    name: "account-user___nl",
    path: "/nl/account/user",
    meta: indexlBtCkxsSoKMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/user/index.vue")
  },
  {
    name: "account-user___es",
    path: "/es/account/user",
    meta: indexlBtCkxsSoKMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/user/index.vue")
  },
  {
    name: "account-user___fr",
    path: "/fr/account/user",
    meta: indexlBtCkxsSoKMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/user/index.vue")
  },
  {
    name: "account-user___de",
    path: "/de/account/user",
    meta: indexlBtCkxsSoKMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/user/index.vue")
  },
  {
    name: "account-user___en",
    path: "/en/account/user",
    meta: indexlBtCkxsSoKMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/user/index.vue")
  },
  {
    name: "admin___nl",
    path: "/nl/admin",
    meta: indexpxLgaj7EPxMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/admin/index.vue")
  },
  {
    name: "admin___es",
    path: "/es/admin",
    meta: indexpxLgaj7EPxMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/admin/index.vue")
  },
  {
    name: "admin___fr",
    path: "/fr/admin",
    meta: indexpxLgaj7EPxMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/admin/index.vue")
  },
  {
    name: "admin___de",
    path: "/de/admin",
    meta: indexpxLgaj7EPxMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/admin/index.vue")
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: indexpxLgaj7EPxMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/admin/index.vue")
  },
  {
    name: "birthday___nl",
    path: "/nl/birthday",
    component: () => import("/usr/local/src/party-pictures-fe/pages/birthday/index.vue")
  },
  {
    name: "birthday___es",
    path: "/es/birthday",
    component: () => import("/usr/local/src/party-pictures-fe/pages/birthday/index.vue")
  },
  {
    name: "birthday___fr",
    path: "/fr/birthday",
    component: () => import("/usr/local/src/party-pictures-fe/pages/birthday/index.vue")
  },
  {
    name: "birthday___de",
    path: "/de/birthday",
    component: () => import("/usr/local/src/party-pictures-fe/pages/birthday/index.vue")
  },
  {
    name: "birthday___en",
    path: "/en/birthday",
    component: () => import("/usr/local/src/party-pictures-fe/pages/birthday/index.vue")
  },
  {
    name: "christmas___nl",
    path: "/nl/christmas",
    component: () => import("/usr/local/src/party-pictures-fe/pages/christmas/index.vue")
  },
  {
    name: "christmas___es",
    path: "/es/christmas",
    component: () => import("/usr/local/src/party-pictures-fe/pages/christmas/index.vue")
  },
  {
    name: "christmas___fr",
    path: "/fr/christmas",
    component: () => import("/usr/local/src/party-pictures-fe/pages/christmas/index.vue")
  },
  {
    name: "christmas___de",
    path: "/de/christmas",
    component: () => import("/usr/local/src/party-pictures-fe/pages/christmas/index.vue")
  },
  {
    name: "christmas___en",
    path: "/en/christmas",
    component: () => import("/usr/local/src/party-pictures-fe/pages/christmas/index.vue")
  },
  {
    name: "company-outing___nl",
    path: "/nl/company-outing",
    component: () => import("/usr/local/src/party-pictures-fe/pages/company-outing/index.vue")
  },
  {
    name: "company-outing___es",
    path: "/es/company-outing",
    component: () => import("/usr/local/src/party-pictures-fe/pages/company-outing/index.vue")
  },
  {
    name: "company-outing___fr",
    path: "/fr/company-outing",
    component: () => import("/usr/local/src/party-pictures-fe/pages/company-outing/index.vue")
  },
  {
    name: "company-outing___de",
    path: "/de/company-outing",
    component: () => import("/usr/local/src/party-pictures-fe/pages/company-outing/index.vue")
  },
  {
    name: "company-outing___en",
    path: "/en/company-outing",
    component: () => import("/usr/local/src/party-pictures-fe/pages/company-outing/index.vue")
  },
  {
    name: "end-of-year___nl",
    path: "/nl/end-of-year",
    component: () => import("/usr/local/src/party-pictures-fe/pages/end-of-year/index.vue")
  },
  {
    name: "end-of-year___es",
    path: "/es/end-of-year",
    component: () => import("/usr/local/src/party-pictures-fe/pages/end-of-year/index.vue")
  },
  {
    name: "end-of-year___fr",
    path: "/fr/end-of-year",
    component: () => import("/usr/local/src/party-pictures-fe/pages/end-of-year/index.vue")
  },
  {
    name: "end-of-year___de",
    path: "/de/end-of-year",
    component: () => import("/usr/local/src/party-pictures-fe/pages/end-of-year/index.vue")
  },
  {
    name: "end-of-year___en",
    path: "/en/end-of-year",
    component: () => import("/usr/local/src/party-pictures-fe/pages/end-of-year/index.vue")
  },
  {
    name: "event-name-event___nl",
    path: "/nl/event/:name()/:event()",
    meta: _91event_931ZY591QiaMMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/event/[name]/[event].vue")
  },
  {
    name: "event-name-event___es",
    path: "/es/event/:name()/:event()",
    meta: _91event_931ZY591QiaMMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/event/[name]/[event].vue")
  },
  {
    name: "event-name-event___fr",
    path: "/fr/event/:name()/:event()",
    meta: _91event_931ZY591QiaMMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/event/[name]/[event].vue")
  },
  {
    name: "event-name-event___de",
    path: "/de/event/:name()/:event()",
    meta: _91event_931ZY591QiaMMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/event/[name]/[event].vue")
  },
  {
    name: "event-name-event___en",
    path: "/en/event/:name()/:event()",
    meta: _91event_931ZY591QiaMMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/event/[name]/[event].vue")
  },
  {
    name: "event-new___nl",
    path: "/nl/event/new",
    meta: indexCnih7ivg3KMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/event/new/index.vue")
  },
  {
    name: "event-new___es",
    path: "/es/event/new",
    meta: indexCnih7ivg3KMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/event/new/index.vue")
  },
  {
    name: "event-new___fr",
    path: "/fr/event/new",
    meta: indexCnih7ivg3KMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/event/new/index.vue")
  },
  {
    name: "event-new___de",
    path: "/de/event/new",
    meta: indexCnih7ivg3KMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/event/new/index.vue")
  },
  {
    name: "event-new___en",
    path: "/en/event/new",
    meta: indexCnih7ivg3KMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/event/new/index.vue")
  },
  {
    name: "family-gathering___nl",
    path: "/nl/family-gathering",
    component: () => import("/usr/local/src/party-pictures-fe/pages/family-gathering/index.vue")
  },
  {
    name: "family-gathering___es",
    path: "/es/family-gathering",
    component: () => import("/usr/local/src/party-pictures-fe/pages/family-gathering/index.vue")
  },
  {
    name: "family-gathering___fr",
    path: "/fr/family-gathering",
    component: () => import("/usr/local/src/party-pictures-fe/pages/family-gathering/index.vue")
  },
  {
    name: "family-gathering___de",
    path: "/de/family-gathering",
    component: () => import("/usr/local/src/party-pictures-fe/pages/family-gathering/index.vue")
  },
  {
    name: "family-gathering___en",
    path: "/en/family-gathering",
    component: () => import("/usr/local/src/party-pictures-fe/pages/family-gathering/index.vue")
  },
  {
    name: "help-faq___nl",
    path: "/nl/help-faq",
    component: () => import("/usr/local/src/party-pictures-fe/pages/help-faq/index.vue")
  },
  {
    name: "help-faq___es",
    path: "/es/help-faq",
    component: () => import("/usr/local/src/party-pictures-fe/pages/help-faq/index.vue")
  },
  {
    name: "help-faq___fr",
    path: "/fr/help-faq",
    component: () => import("/usr/local/src/party-pictures-fe/pages/help-faq/index.vue")
  },
  {
    name: "help-faq___de",
    path: "/de/help-faq",
    component: () => import("/usr/local/src/party-pictures-fe/pages/help-faq/index.vue")
  },
  {
    name: "help-faq___en",
    path: "/en/help-faq",
    component: () => import("/usr/local/src/party-pictures-fe/pages/help-faq/index.vue")
  },
  {
    name: "how-it-works___nl",
    path: "/nl/how-it-works",
    component: () => import("/usr/local/src/party-pictures-fe/pages/how-it-works/index.vue")
  },
  {
    name: "how-it-works___es",
    path: "/es/how-it-works",
    component: () => import("/usr/local/src/party-pictures-fe/pages/how-it-works/index.vue")
  },
  {
    name: "how-it-works___fr",
    path: "/fr/how-it-works",
    component: () => import("/usr/local/src/party-pictures-fe/pages/how-it-works/index.vue")
  },
  {
    name: "how-it-works___de",
    path: "/de/how-it-works",
    component: () => import("/usr/local/src/party-pictures-fe/pages/how-it-works/index.vue")
  },
  {
    name: "how-it-works___en",
    path: "/en/how-it-works",
    component: () => import("/usr/local/src/party-pictures-fe/pages/how-it-works/index.vue")
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/usr/local/src/party-pictures-fe/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/usr/local/src/party-pictures-fe/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/usr/local/src/party-pictures-fe/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/usr/local/src/party-pictures-fe/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/usr/local/src/party-pictures-fe/pages/index.vue")
  },
  {
    name: "login___nl",
    path: "/nl/login",
    meta: indexskK9UQZug8Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/login/index.vue")
  },
  {
    name: "login___es",
    path: "/es/login",
    meta: indexskK9UQZug8Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/login/index.vue")
  },
  {
    name: "login___fr",
    path: "/fr/login",
    meta: indexskK9UQZug8Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/login/index.vue")
  },
  {
    name: "login___de",
    path: "/de/login",
    meta: indexskK9UQZug8Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/login/index.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: indexskK9UQZug8Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/login/index.vue")
  },
  {
    name: "prices___nl",
    path: "/nl/prices",
    component: () => import("/usr/local/src/party-pictures-fe/pages/prices/index.vue")
  },
  {
    name: "prices___es",
    path: "/es/prices",
    component: () => import("/usr/local/src/party-pictures-fe/pages/prices/index.vue")
  },
  {
    name: "prices___fr",
    path: "/fr/prices",
    component: () => import("/usr/local/src/party-pictures-fe/pages/prices/index.vue")
  },
  {
    name: "prices___de",
    path: "/de/prices",
    component: () => import("/usr/local/src/party-pictures-fe/pages/prices/index.vue")
  },
  {
    name: "prices___en",
    path: "/en/prices",
    component: () => import("/usr/local/src/party-pictures-fe/pages/prices/index.vue")
  },
  {
    name: "privacy-policy___nl",
    path: "/nl/privacy-policy",
    component: () => import("/usr/local/src/party-pictures-fe/pages/privacy-policy/index.vue")
  },
  {
    name: "privacy-policy___es",
    path: "/es/privacy-policy",
    component: () => import("/usr/local/src/party-pictures-fe/pages/privacy-policy/index.vue")
  },
  {
    name: "privacy-policy___fr",
    path: "/fr/privacy-policy",
    component: () => import("/usr/local/src/party-pictures-fe/pages/privacy-policy/index.vue")
  },
  {
    name: "privacy-policy___de",
    path: "/de/privacy-policy",
    component: () => import("/usr/local/src/party-pictures-fe/pages/privacy-policy/index.vue")
  },
  {
    name: "privacy-policy___en",
    path: "/en/privacy-policy",
    component: () => import("/usr/local/src/party-pictures-fe/pages/privacy-policy/index.vue")
  },
  {
    name: "register___nl",
    path: "/nl/register",
    meta: indexPBwaC8dJgTMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/register/index.vue")
  },
  {
    name: "register___es",
    path: "/es/register",
    meta: indexPBwaC8dJgTMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/register/index.vue")
  },
  {
    name: "register___fr",
    path: "/fr/register",
    meta: indexPBwaC8dJgTMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/register/index.vue")
  },
  {
    name: "register___de",
    path: "/de/register",
    meta: indexPBwaC8dJgTMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/register/index.vue")
  },
  {
    name: "register___en",
    path: "/en/register",
    meta: indexPBwaC8dJgTMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/register/index.vue")
  },
  {
    name: "reset-password___nl",
    path: "/nl/reset-password",
    meta: indexiPX292p0ygMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/reset-password/index.vue")
  },
  {
    name: "reset-password___es",
    path: "/es/reset-password",
    meta: indexiPX292p0ygMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/reset-password/index.vue")
  },
  {
    name: "reset-password___fr",
    path: "/fr/reset-password",
    meta: indexiPX292p0ygMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/reset-password/index.vue")
  },
  {
    name: "reset-password___de",
    path: "/de/reset-password",
    meta: indexiPX292p0ygMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/reset-password/index.vue")
  },
  {
    name: "reset-password___en",
    path: "/en/reset-password",
    meta: indexiPX292p0ygMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/reset-password/index.vue")
  },
  {
    name: "sport-event___nl",
    path: "/nl/sport-event",
    component: () => import("/usr/local/src/party-pictures-fe/pages/sport-event/index.vue")
  },
  {
    name: "sport-event___es",
    path: "/es/sport-event",
    component: () => import("/usr/local/src/party-pictures-fe/pages/sport-event/index.vue")
  },
  {
    name: "sport-event___fr",
    path: "/fr/sport-event",
    component: () => import("/usr/local/src/party-pictures-fe/pages/sport-event/index.vue")
  },
  {
    name: "sport-event___de",
    path: "/de/sport-event",
    component: () => import("/usr/local/src/party-pictures-fe/pages/sport-event/index.vue")
  },
  {
    name: "sport-event___en",
    path: "/en/sport-event",
    component: () => import("/usr/local/src/party-pictures-fe/pages/sport-event/index.vue")
  },
  {
    name: "terms-and-conditions___nl",
    path: "/nl/terms-and-conditions",
    component: () => import("/usr/local/src/party-pictures-fe/pages/terms-and-conditions/index.vue")
  },
  {
    name: "terms-and-conditions___es",
    path: "/es/terms-and-conditions",
    component: () => import("/usr/local/src/party-pictures-fe/pages/terms-and-conditions/index.vue")
  },
  {
    name: "terms-and-conditions___fr",
    path: "/fr/terms-and-conditions",
    component: () => import("/usr/local/src/party-pictures-fe/pages/terms-and-conditions/index.vue")
  },
  {
    name: "terms-and-conditions___de",
    path: "/de/terms-and-conditions",
    component: () => import("/usr/local/src/party-pictures-fe/pages/terms-and-conditions/index.vue")
  },
  {
    name: "terms-and-conditions___en",
    path: "/en/terms-and-conditions",
    component: () => import("/usr/local/src/party-pictures-fe/pages/terms-and-conditions/index.vue")
  },
  {
    name: "vacation___nl",
    path: "/nl/vacation",
    component: () => import("/usr/local/src/party-pictures-fe/pages/vacation/index.vue")
  },
  {
    name: "vacation___es",
    path: "/es/vacation",
    component: () => import("/usr/local/src/party-pictures-fe/pages/vacation/index.vue")
  },
  {
    name: "vacation___fr",
    path: "/fr/vacation",
    component: () => import("/usr/local/src/party-pictures-fe/pages/vacation/index.vue")
  },
  {
    name: "vacation___de",
    path: "/de/vacation",
    component: () => import("/usr/local/src/party-pictures-fe/pages/vacation/index.vue")
  },
  {
    name: "vacation___en",
    path: "/en/vacation",
    component: () => import("/usr/local/src/party-pictures-fe/pages/vacation/index.vue")
  },
  {
    name: "wedding-anniversary___nl",
    path: "/nl/wedding-anniversary",
    component: () => import("/usr/local/src/party-pictures-fe/pages/wedding-anniversary/index.vue")
  },
  {
    name: "wedding-anniversary___es",
    path: "/es/wedding-anniversary",
    component: () => import("/usr/local/src/party-pictures-fe/pages/wedding-anniversary/index.vue")
  },
  {
    name: "wedding-anniversary___fr",
    path: "/fr/wedding-anniversary",
    component: () => import("/usr/local/src/party-pictures-fe/pages/wedding-anniversary/index.vue")
  },
  {
    name: "wedding-anniversary___de",
    path: "/de/wedding-anniversary",
    component: () => import("/usr/local/src/party-pictures-fe/pages/wedding-anniversary/index.vue")
  },
  {
    name: "wedding-anniversary___en",
    path: "/en/wedding-anniversary",
    component: () => import("/usr/local/src/party-pictures-fe/pages/wedding-anniversary/index.vue")
  },
  {
    name: "wedding___nl",
    path: "/nl/wedding",
    component: () => import("/usr/local/src/party-pictures-fe/pages/wedding/index.vue")
  },
  {
    name: "wedding___es",
    path: "/es/wedding",
    component: () => import("/usr/local/src/party-pictures-fe/pages/wedding/index.vue")
  },
  {
    name: "wedding___fr",
    path: "/fr/wedding",
    component: () => import("/usr/local/src/party-pictures-fe/pages/wedding/index.vue")
  },
  {
    name: "wedding___de",
    path: "/de/wedding",
    component: () => import("/usr/local/src/party-pictures-fe/pages/wedding/index.vue")
  },
  {
    name: "wedding___en",
    path: "/en/wedding",
    component: () => import("/usr/local/src/party-pictures-fe/pages/wedding/index.vue")
  },
  {
    name: component_45stubkgZDd53JZpMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubkgZDd53JZp
  }
]