import { mapDropboxConnectedDriveData } from '~/lib/mappers/connected-drive-mapper'

/**
 * @typedef {DropboxService}
 * @alias this.$dropboxService
 */
export class DropboxService {
  constructor(nuxtApp) {
    this.config = nuxtApp.$config.public
    this.nuxtApp = nuxtApp
    this.clientId = this.config.dropboxClientId
    this.redirectUrl = this.config.dropboxRedirectUrl
  }

  init() {
    this.$apiService = this.nuxtApp.$apiService
  }

  /**
   * @returns {void} consentUrl
   */
  getConsent() {
    const oAuthEndpoint = 'https://www.dropbox.com/oauth2/authorize'

    // Create <form> element to submit parameters to OAuth 2.0 endpoint.
    const form = document.createElement('form')
    form.setAttribute('method', 'GET') // Send as a GET request.
    form.setAttribute('action', oAuthEndpoint)

    // Parameters to pass to OAuth 2.0 endpoint.
    const params = {
      client_id: this.clientId,
      redirect_uri: this.redirectUrl,
      token_access_type: 'offline',
      prompt: 'consent',
      response_type: 'code',
      state: 'dropbox',
    }

    // Add form parameters as hidden input values.
    for (const p in params) {
      const input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', p)
      input.setAttribute('value', params[p])
      form.appendChild(input)
    }

    // Add form to page and submit it to open the OAuth 2.0 endpoint.
    document.body.appendChild(form)
    form.submit()
  }

  /**
   * @returns {Promise<momentshare.models.user.ConnectedDriveData>}
   */
  getDropboxUserData() {
    return this.$apiService.instance
      .get('connector/dropbox/user')
      .then((response) => mapDropboxConnectedDriveData(response.data))
  }

  /**
   * @param {string} code
   * @returns {Promise<string>}
   */
  setDropboxTokens(code) {
    return this.$apiService.instance.post('connector/dropbox/set-dropbox-tokens', { code })
  }

  /**
   * @returns {Promise<string>}
   */
  deleteDropboxTokens() {
    return this.$apiService.instance.delete('connector/dropbox/delete-dropbox-tokens')
  }
}
