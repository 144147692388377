import { useUserStore } from '~/stores/user'
import { USER_AUTH_COOKIE_KEY } from '~/lib/constants/cookies'
import { useEventsStore } from '~/stores/events'

/**
 * @typedef {UserService}
 * @alias this.$userService
 */
export class UserService {
  constructor(nuxtApp) {
    this.nuxtApp = nuxtApp
  }

  init() {
    this.$apiService = this.nuxtApp.$apiService
    this.$clientCookieService = this.nuxtApp.$clientCookieService
  }

  /**
   * @param {string} username
   * @param {string} password
   * @param {string} displayName
   * @param {string} localeCode
   * @returns {Promise<momentshare.models.user.User>}
   */
  async register({ username, password, displayName, localeCode }) {
    await this.$apiService.register({ username, password, displayName, localeCode })

    return this.fetchUser()
  }

  /**
   * @param {string} username
   * @param {string} password
   * @returns {Promise<momentshare.models.user.User>}
   */
  async login({ username, password } = {}) {
    await this.$apiService.login(username, password)

    return this.fetchUser()
  }

  /**
   * @returns {Promise<momentshare.models.user.User>}
   */
  async fetchUser() {
    let user = await this.$apiService.getUser()

    if (user) {
      this.setUserInStore(user)

      return user
    }
  }

  /**
   * @param {string} userName
   * @returns {Promise<String>}
   */
  async resetPassword(userName) {
    return this.$apiService.instance.post('user/reset-password', { userName })
  }

  /**
   * @param {string} code
   * @param {string} password
   * @returns {Promise<String>}
   */
  async newPassword({ code, password }) {
    return this.$apiService.instance.post('user/new-password', { code, password })
  }

  /**
   * @param {momentshare.models.user.User} user
   * @returns {void}
   */
  setUserInStore(user) {
    const userStore = useUserStore()

    userStore.setUser(user)
  }

  /**
   * @param {momentshare.models.user.UserAddress} address
   * @returns {Promise}
   */
  async updateUserAddress(address) {
    const response = await this.$apiService.instance.put('user/address', { ...address })

    await this.fetchUser()

    return response
  }

  /**
   * @param {momentshare.models.user.UserUpdateDetails} userUpdateDetails
   * @returns {Promise}
   */
  async updateUserDetails(userUpdateDetails) {
    const response = await this.$apiService.instance.put('user/details', { ...userUpdateDetails })

    await this.fetchUser()

    return response
  }

  /**
   * @param {string} language
   * @returns {Promise}
   */
  async updateUserlanguage(language) {
    return this.$apiService.instance.put('user/language', { newLanguage: language })
  }

  /**
   * @param {momentshare.models.user.UserAddress} address
   * @returns {Promise}
   */
  async setUserAddress(address) {
    const response = await this.$apiService.instance.post('user/address', { ...address })

    await this.fetchUser()

    return response
  }

  logout() {
    const userStore = useUserStore()
    const eventStore = useEventsStore()

    eventStore.reset()
    userStore.logout()

    this.$clientCookieService.delete(USER_AUTH_COOKIE_KEY)
  }
}
